import { _Success } from "/util/lens/RemoteData";
import { prop, compose, get } from "partial.lenses";
import {
  ACH_RESOURCE,
  ADDRESS_RESOURCE,
  CREDIT_CARD_RESOURCE,
  EMAIL_RESOURCE,
  PAYMENT_SCHEDULES_RESOURCE,
  PHONE_RESOURCE
} from "./Profile.state";
import { groupBy, isEmpty, values } from "ramda";
import {
  attachConfigValues,
  cleanSubClientName,
  formatName
} from "/util/general";
import dayjs from "dayjs";
import localizedFormat from "dayjs/plugin/localizedFormat";
import { STANDARD_ITEM_TYPE } from "../../../checkout/pages/multi-cart/state/ShoppingCart.selectors";
import { kebabCaseToSnakeCase } from "../../../../util/general";
dayjs.extend(localizedFormat);

// Selectors for Profile.state

// Settings
export const _resources = compose(prop("settings"), prop("resources"));
export const _detailedObligation = compose(
  prop("settings"),
  prop("detailedObligation")
);
export const _requests = compose(prop("settings"), prop("requests"));
export const _automaticPayment = compose(
  prop("settings"),
  prop("automaticPaymentData")
);
export const _selectedPaymentForm = compose(
  prop("settings"),
  prop("selectedPaymentForm")
);
export const _paymentPlan = compose(prop("settings"), prop("paymentPlanData"));

// Customer Management
export const _customerManagementProfile = compose(
  prop("customerManagement"),
  prop("profileId")
);
export const getCustomerManagement = state =>
  get(_customerManagementProfile, state);

export const _resourcesSuccess = compose(_resources, _Success);
// export const _resource = resourceType => id =>
//   compose(_resourceSuccess(resourceType), prop(id));
export const _resourceSuccess = resourceType =>
  compose(_resources, _Success, prop(resourceType));

export const _changeRequest = settingName =>
  compose(_requests, prop(settingName));

export const _automaticPaymentData = settingName =>
  compose(_automaticPayment, prop(settingName));

export const _paymentPlanData = settingName =>
  compose(_paymentPlan, prop(settingName));

export const _resourcePageSuccess = (resourceType, page = 1) =>
  compose(
    _resources,
    _Success,
    prop(resourceType),
    prop("pages"),
    prop(page?.toString())
  );

export const _resourceIdSuccess = (resourceType, id = 1) =>
  compose(
    _resources,
    _Success,
    prop(resourceType),
    prop("ids"),
    prop(id?.toString())
  );

// Forms
export const getForms = prop("forms");
export const getForm = formKey => compose(getForms, prop(formKey));

export const getAddCreditCardForm = getForm("addCreditCardForm");
export const getAddAchForm = getForm("addAchForm");
export const getPhoneForm = getForm("phoneForm");
export const getEmailForm = getForm("emailForm");
export const getAddressForm = getForm("addressForm");
export const getEditNameForm = getForm("editNameForm");
export const getChangePasswordForm = getForm("changePasswordForm");

//checkout forms
export const getCheckoutCreditCardForm = getForm("creditCardForm");
export const getCheckoutBankAccountForm = getForm("achForm");

// External Use

// Top level
export const getState = key => prop(key);
export const getSettings = key => compose(getState(key), prop("settings"));
export const getAlertBars = key => compose(getState(key), prop("alertBars"));

// Resources
export const getProfileResources = key => state =>
  get(compose(getState(key), _resources), state);
const _resourcesExternal = key => compose(getState(key), _resources);
export const getSavedCreditCards = key => state =>
  get(
    compose(_resourcesExternal(key), _Success, prop(CREDIT_CARD_RESOURCE)),
    state
  );
export const getSavedBankAccounts = key => state =>
  get(compose(_resourcesExternal(key), _Success, prop(ACH_RESOURCE)), state);
export const getSavedAddresses = key => state =>
  get(
    compose(_resourcesExternal(key), _Success, prop(ADDRESS_RESOURCE)),
    state
  );
export const getSavedEmails = key => state =>
  get(compose(_resourcesExternal(key), _Success, prop(EMAIL_RESOURCE)), state);
export const getSavedPhoneNumbers = key => state =>
  get(compose(_resourcesExternal(key), _Success, prop(PHONE_RESOURCE)), state);
export const getAccounts = key => state =>
  get(
    compose(_resourcesExternal(key), _Success, prop(ACCOUNT_RESOURCE)),
    state
  );
export const getObligations = key => state => {
  const resourcePath = _resourcesExternal(key).flat();
  const obligationsPath = compose(
    ...resourcePath,
    prop("value"),
    prop("obligations")
  );
  return get(obligationsPath, state);
};
export const getSchedules = key => state =>
  get(
    compose(
      _resourcesExternal(key),
      _Success,
      prop(PAYMENT_SCHEDULES_RESOURCE)
    ),
    state
  );

// Settings
export const getContactCard = state =>
  get(["global", "settings", "data", "contactCard"], state);
export const getShowContactCard = state =>
  get(["global", "settings", "data", "showContactCard"], state);
export const getHeroImage = state =>
  get(["global", "settings", "data", "hero"], state);
export const getLinkCards = state =>
  get(["global", "settings", "data", "linkCards"], state);

// Other
export const getClientDisplayName = state =>
  get(["global", "clientMetadata", "data", "clientDisplayName"], state);
export const getHasAutopay = state =>
  get(["global", "clientMetadata", "data", "hasAutopay"], state);
export const getDeniedCardsForClient = state =>
  get(
    ["global", "payment", "data", "default", "options", "deniedCards"],
    state
  );
export const getConstituentID = state =>
  get(["global", "localStorage", "constituentID"], state);
export const getPaymentInstrumentId = state =>
  get(
    ["settings", "automaticPaymentData", "selectedAutopaymentMethodId"],
    state
  );
export const getDetailedObligationAssociationId = state =>
  get(["settings", "detailedObligation", "assocID"], state);

export const getAllCreditCards = state =>
  get(["settings", "resources", "value", "creditCards"], state);

export const getRawOblAssocs = state =>
  get(["settings", "resources", "value", "obligations", "rawOblAssocs"], state);

export const objToArray = obj =>
  Object.values(obj).reduce((acc, value) => [...acc, { ...value }], []);

export const arrayToObj = array =>
  array.reduce((acc, curr) => ({ ...acc, [curr.key]: curr.value }), {});

// Retrieve CCs and Bank Accounts and clean up properties for display to user
// Extract only properties needed for display from resource
export const getCreditCardsForDisplay = resources =>
  values(resources[CREDIT_CARD_RESOURCE]).map(card => ({
    isPrimary: card.isPrimary,
    lastFour: card.lastFour,
    id: card.id,
    kind: card.kind,
    expireDate: `${card.expiryMonth}/${card.expiryYear}`,
    expirationStatus: card.expirationStatus
  }));

export const getBankAccountsForDisplay = resources =>
  values(resources[ACH_RESOURCE]).map(ach => ({
    isPrimary: ach.isPrimary,
    accountType: ach.accountType,
    lastFour: ach.lastFour,
    id: ach.id,
    kind: ach.kind
  }));

// Format, modify, and group obligation association resources and obligations

const ACCOUNT_OBLIGATION_TYPE = "ACCOUNT";
const MISSING_OBLIGATION_TYPE = "MISSING";

const attachObligationConfigs = (associations, configs) =>
  associations.map(assoc => {
    const filteredConfig =
      configs.filter(
        // make sure both slugs are using - instead of _ before comparison
        config =>
          config.obligationSlug.replace(/_/g, "-") ===
          assoc.subClientSlug.replace(/_/g, "-")
      )?.[0] ?? {};
    if (isEmpty(filteredConfig)) {
      console.log(
        `%cError: No configuration found that matches obligation association. Check that ${assoc.subClientSlug} is present in configuration file as obligationSlug.`,
        "background-color: rgba(235, 64, 52, 0.25); color: #eb4034; display: inline-block; margin: 0.5rem; padding: 0.5rem; border: 1px solid #eb4034;"
      );
    }

    /*
      Creates a loading obligation object with default values for custom attributes.
      Uses assoc (association details), config (obligation and collection details).
      Returns a loading obligation object with custom attributes and loading status.
    */
    const createLoadingObligation = (assoc, config) => {
      const obligationDetailsItems = config?.details?.obligationDetails
        ? Object.values(config.details.obligationDetails)?.map(val => ({
            key: val,
            value: ""
          }))
        : [];
      const collectionDetailsListItems = config?.details?.collectionDetailsList?.map(
        val => ({
          key: val.attribute,
          value: ""
        })
      );
      const customAttributes = [
        ...obligationDetailsItems,
        ...collectionDetailsListItems,
        { key: "account_number", value: assoc.account },
        { key: "client_slug", value: assoc.clientSlug },
        { key: "sub_client_slug", value: assoc.subClientSlug }
      ];
      return {
        id: assoc.account,
        config: config,
        customAttributes: customAttributes,
        isLoading: true
      };
    };

    // uses utility functions to build out certain obligation values (like desc/subdesc)
    // and to transform obligation's custom attributes from key/value array to object indexed by key
    const transformObligation = (obl, config) =>
      attachConfigValues(config, {
        ...obl,
        customAttributes: arrayToObj(obl.customAttributes)
      });
    const modifiedObligations =
      assoc.isLoading || assoc.error
        ? [createLoadingObligation(assoc, filteredConfig)].map(obl =>
            transformObligation(obl, filteredConfig)
          )
        : assoc.obligations?.map(obl =>
            transformObligation(obl, filteredConfig)
          ) ?? [];
    return {
      ...assoc,
      config: filteredConfig,
      obligations: modifiedObligations
    };
  });

const groupAssocByType = groupBy(association => {
  const obligationType = association?.config?.type ?? MISSING_OBLIGATION_TYPE;
  if (obligationType === MISSING_OBLIGATION_TYPE) {
    console.warn(
      "%cWarning: type of obligation (account/property) missing from obligation configuration, or configuration misssing entirely. Please check FCS configuration and be sure obligation subClientSlug and FCS obligationSlug match.",
      "background-color: rgba(235, 64, 52, 0.25); color: #eb4034; display: inline-block; margin: 0.5rem; padding: 0.5rem; border: 1px solid #eb4034;"
    );
  }
  return obligationType === ACCOUNT_OBLIGATION_TYPE ? "accounts" : "properties";
});

// If the obligation association is loading or encounters an error (obligation fetch failed to load or timed out), it is considered active.
// Otherwise, if the obligation fetch returns an empty obligations list, it is considered inactive.
const groupObligationAssocByStatus = groupBy(association =>
  association.isLoading ||
  association.error ||
  (association.obligations && !isEmpty(association.obligations))
    ? "active"
    : "inactive"
);

const groupObligationAssocBySubClient = groupBy(association =>
  formatName(
    cleanSubClientName(association.clientSlug, association.subClientSlug)
  )
);

export const cleanAndFormatObligations = (oblAssocs, configs) => {
  const assocsWithConfigs = attachObligationConfigs(oblAssocs, configs);
  const { accounts = [], properties = [] } = groupAssocByType(
    assocsWithConfigs
  );
  const statusGroupedAccounts = groupObligationAssocByStatus(accounts);
  const statusGroupedProperties = groupObligationAssocByStatus(properties);
  return {
    rawOblAssocs: oblAssocs,
    accounts: {
      active: groupObligationAssocBySubClient(
        statusGroupedAccounts?.active ?? []
      ),
      inactive: groupObligationAssocBySubClient(
        statusGroupedAccounts?.inactive ?? []
      )
    },
    properties: {
      active: groupObligationAssocBySubClient(
        statusGroupedProperties?.active ?? []
      ),
      inactive: groupObligationAssocBySubClient(
        statusGroupedProperties?.inactive ?? []
      )
    }
  };
};

export const isObligationAssocDeleted = (oblAssocs, id) =>
  !oblAssocs?.find(assoc => assoc.id === id);

export const updateExistingAssociationInList = ({
  associations,
  id,
  newValues,
  config
}) => {
  const findAssociationById = (assocs, id) => {
    const index = assocs?.findIndex(assoc => assoc.id === id);
    return { index, association: assocs[index] ?? null };
  };

  const { index, association } = findAssociationById(associations, id);
  const updatedAssoc = association
    ? { ...association, ...newValues }
    : newValues;

  if (index >= 0) {
    associations[index] = updatedAssoc;
  } else {
    associations.push(updatedAssoc);
  }
  return cleanAndFormatObligations(associations, config);
};

export const getNextAutopayDate = schedules => (assocID = "") => {
  return schedules.reduce(
    (acc, curr) =>
      curr.obligationAssociationId === assocID
        ? curr.nextOccurrenceOn === null || curr.nextOccurrenceOn === undefined
          ? "On"
          : dayjs(curr.nextOccurrenceOn).format("LL")
        : acc,
    ""
  );
};

// Profile Cart Functions - Used in Accounts and AccountDetails
export const createLineItems = association => {
  if (!association.obligations) return [];
  const getLineItemCustomAttributes = obl =>
    association.config.details.collectionDetailsList
      .filter(item => obl.customAttributes[item.attribute])
      .map(item => ({
        key: item.attribute,
        value: obl.customAttributes[item.attribute]
      }));
  return association.obligations.map(obl => ({
    description: obl.details?.description,
    subDescription: obl.details?.subDescription,
    amount: obl.paymentAttributes?.amount,
    customAttributes: getLineItemCustomAttributes(obl),
    itemType: STANDARD_ITEM_TYPE,
    icon: association.config.iconDefault,
    obligationAssociationId: association.id,
    isBundled: association.obligations?.length > 1
  }));
};

export const createCartConfig = (association, lineItems, paymentConfigs) => ({
  serviceName: association?.subClientSlug,
  customAttributes: paymentConfigs[association?.subClientSlug].customAttributes,
  accountId: association.account,
  subClientSlug: association?.subClientSlug,
  addMoreItemsURL: "/profile/accounts",
  collectionDetailsList: association.config.details.collectionDetailsList,
  accountLookupConfigKey: kebabCaseToSnakeCase(association?.subClientSlug),
  // This is needed because the Obligation component dispatches the addToCart action
  addToCartConfig: {
    items: lineItems,
    itemType: STANDARD_ITEM_TYPE
  },
  allowedPaymentInstruments:
    association.obligations?.[0]?.allowedPaymentInstruments
});

export const checkIsInCart = (carts, association) =>
  carts[association?.subClientSlug]?.items.some(
    item => item?.obligationAssociationId === association.id
  ) ?? false;

import styled from "styled-components";
import { Box, Cluster, Stack } from "../../atoms/layouts";
import ButtonWithLink from "../../atoms/button-with-link/ButtonWithLink";
import { adjustHexColor } from "../../../util/general";

export const BannerContainer = styled(Cluster)`
  background: ${({ themeValues }) =>
    adjustHexColor(themeValues.background, 10, "lighten")};
  padding: 2rem;
`;

export const ContentContainer = styled(Cluster)`
  padding: 0;
  width: ${({ isMobile }) => (isMobile ? "100%" : " 1224px")};
  > div {
    flex-direction: ${({ isMobile }) => (isMobile ? "column" : "row")};
  }
`;

export const ButtonContainer = styled(Stack)`
  align-items: center;
  width: ${({ isMobile }) => (isMobile ? "100%" : "222px")};
  padding-left: ${({ isMobile }) => (isMobile ? "0" : "2rem")};
  > * {
    width: inherit;
  }
`;

export const RegisterLink = styled(ButtonWithLink)`
  border-radius: 100px;
  margin: 0;
  width: 100%;
`;

export const LoginLink = styled(ButtonWithLink)`
  border-radius: 100px;
  padding: 0;
  margin: 0;
  width: 100%;
  min-height: 2rem;
  margin-top: 0px;
  border: none;
  &:hover {
    text-decoration: none;
  }
`;

import React, { Fragment, lazy } from "react";
import { Helmet } from "react-helmet";
import { useParams } from "react-router-dom";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { injectScreendoorScript } from "../../util/router-utils";
import ProfileFindAccount from "../profile/pages/user-profile/sub-pages/profile-find-account/FindAccount";
import NotFound from "../common/pages/not-found";
import { history } from "../../state/store";
import Redirect from "../common/pages/redirect";

const Service = lazy(() => import("./pages/service"));
const ScreendoorForm = lazy(() => import("./pages/screendoor-form"));
const CMSPage = lazy(() => import("./pages/cms"));

const SERVICE = "/service/:slug/*";
const SCREENDOOR_FORM = "/form/:projectId";
const CMS = "/:pageSlug";
const CMS_PREVIEW = "/:pageSlug/:id";
const PAY_DOT_OR_ROOT = "/"; // PayDot landing page rendered if it is enabled, e.g. pay.{city}.com. If disabled, redirects to "/login".
const PAY_DOT_ACCOUNTS = "/accounts"; // E.g. pay.{city}.com/accounts.

const RouteHelmet = ({
  metadata,
  useContactName,
  title,
  screendoorForm,
  projectIds
}) => {
  const { projectId } = useParams();
  return (
    <Helmet
      onChangeClientState={
        screendoorForm
          ? (_, addedTags) =>
              injectScreendoorScript(projectIds[projectId], addedTags)
          : () => {}
      }
    >
      {screendoorForm && (
        <script
          type="text/javascript"
          nonce="06a91385ca964637d0a82642a15722c7"
          src="//code.jquery.com/jquery-2.2.3.min.js"
        ></script>
      )}
      {screendoorForm && (
        <link
          href="//d3q1ytufopwvkq.cloudfront.net/1/formrenderer.css"
          rel="stylesheet"
        />
      )}
      {screendoorForm && (
        <link
          rel="stylesheet"
          href="https://unpkg.com/leaflet@1.6.0/dist/leaflet.css"
        />
      )}
      {screendoorForm && (
        <script
          type="text/javascript"
          nonce="06a91385ca964637d0a82642a15722c7"
          src="//d3q1ytufopwvkq.cloudfront.net/1/formrenderer.js"
        ></script>
      )}
      <title>
        {useContactName ? `${metadata?.contactName} ${title}` : title}
      </title>
      <link rel="shortcut icon" href={metadata?.favicon ?? ""} />
    </Helmet>
  );
};

const ProfilePageContainer = ({ onLogout, isLoggedIn, children }) => (
  <Fragment>
    <Header key="main-header" isLoggedIn={isLoggedIn} onLogout={onLogout} />
    {children}
    <Footer key="main-footer" showLogout={isLoggedIn} onLogout={onLogout} />
  </Fragment>
);

const PayDotRoute = props => {
  const { isLoggedIn, onLogout, clientMetadata } = props;

  if (isLoggedIn) {
    return <Redirect redirectTo="/profile/find" />;
  }

  return (
    <>
      <RouteHelmet useContactName metadata={clientMetadata?.data} title="Pay" />
      <ProfilePageContainer onLogout={onLogout} isLoggedIn={isLoggedIn}>
        <ProfileFindAccount {...props} />
      </ProfilePageContainer>
    </>
  );
};

export const routes = {
  // This route uses some Profile-specific components and logic, sharing the FindAccount component with Profile.
  // Unlike the Profile route, the root ("/") is an unauthenticated route and is not prefixed with "/profile", such as {root}/profile/find.
  // The PayDot landing page is rendered if it is enabled (e.g., pay.{city}.com). If disabled, it redirects to "/login".
  [PAY_DOT_OR_ROOT]: props =>
    props.payDotEnabled ? PayDotRoute(props) : <Redirect redirectTo="/login" />,
  [PAY_DOT_ACCOUNTS]: props =>
    props.payDotEnabled ? (
      PayDotRoute(props)
    ) : (
      <Fragment>
        <Header
          key="main-header"
          isLoggedIn={props.isLoggedIn}
          onLogout={props.onLogout}
        />
        <NotFound {...props} history={history} />
        <Footer
          key="main-footer"
          showLogout={props.isLoggedIn}
          onLogout={props.onLogout}
        />
      </Fragment>
    ),
  [SERVICE]: props => (
    <Fragment>
      <RouteHelmet
        metadata={props.clientMetadata?.data}
        title={props.workflowMetadata?.workflowPageName ?? ""}
      />
      <ProfilePageContainer
        onLogout={props.onLogout}
        isLoggedIn={props.isLoggedIn}
      >
        <Service {...props} />
      </ProfilePageContainer>
    </Fragment>
  ),
  [SCREENDOOR_FORM]: props => (
    <Fragment>
      <RouteHelmet
        metadata={props.clientMetadata?.data}
        projectIds={props.clientMetadata?.data?.screendoorProjectIDs}
        title={
          props.clientMetadata?.data?.clientDisplayName ?? "Screendoor Form"
        }
        screendoorForm
      />
      <ProfilePageContainer
        onLogout={props.onLogout}
        isLoggedIn={props.isLoggedIn}
      >
        <ScreendoorForm {...props} />
      </ProfilePageContainer>
    </Fragment>
  ),
  [CMS]: props => (
    <Fragment>
      <CMSPage {...props} />
    </Fragment>
  ),
  [CMS_PREVIEW]: props => (
    <Fragment>
      <CMSPage {...props} />
    </Fragment>
  )
};

import {
  MATISSE_BLUE,
  ROYAL_BLUE_VIVID,
  WHITE
} from "../../../constants/colors";
import { FONT_SIZE } from "../../../constants/style_constants";

// Colors
const heroPrimaryColor = ROYAL_BLUE_VIVID;
const heroTextColor = WHITE;

// Font Sizes
const headingFontSize = {
  v1: {
    mobile: "3.00rem",
    desktop: "3.50rem"
  },
  v2: {
    mobile: "2.50rem",
    desktop: "3.50rem"
  }
};
const subheadingFontSize = {
  v1: {
    mobile: "2.00rem",
    desktop: "2.50rem"
  }
};

const descriptionFontSize = {
  v1: {
    mobile: FONT_SIZE.MD,
    desktop: FONT_SIZE.XL
  },
  v2: {
    mobile: FONT_SIZE.LG,
    desktop: FONT_SIZE.XL
  }
};

// Line Heights
const headingLineHeight = {
  v1: {
    mobile: 1.25,
    desktop: 1.15
  },
  v2: {
    mobile: 1.25,
    desktop: 1.15
  }
};

const subheadingLineHeight = {
  v1: {
    mobile: 1.5,
    desktop: 1.15
  }
};

const descriptionLineHeight = {
  v1: {
    mobile: 1.5,
    desktop: 1.5
  },
  v2: {
    mobile: 1.5,
    desktop: 1.5
  }
};

// Alignment
const textAlign = {
  v1: {
    mobile: "left",
    desktop: "left"
  },
  v2: {
    mobile: "left",
    desktop: "center"
  }
};

const justifyContent = {
  v1: {
    mobile: "flex-start",
    desktop: "flex-start"
  },
  v2: {
    mobile: "flex-start",
    desktop: "center"
  }
};

export const fallbackValues = {
  // Colors
  heroPrimaryColor,
  heroTextColor,
  // Font sizes
  headingFontSize,
  descriptionFontSize,
  subheadingFontSize,
  // Line heights
  headingLineHeight,
  subheadingLineHeight,
  descriptionLineHeight,
  // Alignment
  textAlign,
  justifyContent
};

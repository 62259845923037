import React, { useState, useContext, useEffect } from "react";
import * as R from "ramda";
import parse from "html-react-parser";
import dompurify from "dompurify";
import { ThemeContext } from "styled-components";
import { useLocation, useParams, useNavigate } from "react-router-dom";
import { connect } from "react-redux";
import {
  Box,
  Stack,
  Cluster,
  Switcher,
  Center,
  Cover,
  Heading,
  Paragraph,
  Motion,
  CollapsibleSection,
  EditableTable,
  TableListItem,
  ButtonWithAction,
  ButtonWithLink,
  Text,
  AmountCallout,
  AutopayOnIcon,
  withWindowSize,
  ToggleSwitch,
  util,
  constants,
  Loading
} from "@thecb/components";
import {
  AccountDetailsModal,
  RemoveAccountModal,
  PaperlessBillingInfoModal
} from "./AccountDetailsModal";
import AlertBar from "/components/alert-bar";
import {
  capitalize,
  formatName,
  isAutoPayEnabled,
  retrieveAutoPayData
} from "/util/general";
import {
  checkIsInCart,
  createCartConfig,
  createLineItems,
  getNextAutopayDate as getDueDate
} from "../../Profile.selectors";
import { isInCustomerManagement } from "../../../../../../util/router-utils";
import { PaperlessBillingConfirmModal } from "./AccountDetailsModal";
import ContentWithLoader from "../../../../../../components/content-with-loader";
import { STANDARD_ITEM_TYPE } from "../../../../../checkout/pages/multi-cart/state/ShoppingCart.selectors";

const AccountDetails = ({
  resourcesActions,
  alertBars,
  actions,
  autoPayData,
  paymentPlanData,
  resources,
  obligationAssociations,
  detailedObligation,
  canRemoveObligation,
  paymentConfigs,
  cartEnabled,
  carts
}) => {
  const { id: accountsAgency } = useParams();
  let navigate = useNavigate();
  let location = useLocation();
  let pathSegments = location.pathname.split("/");
  let topLevel = pathSegments.filter((_, index) => index <= 2).join("/");
  const [obligationAssociationState, setObligationAssociationState] = useState({
    isLoading: true,
    failedToLoad: false
  });

  /* To prevent the loading spinner from flashing on the screen */
  const [minTimeElapsed, setMinTimeElapsed] = useState(false);
  useEffect(() => {
    const minTimeElapsedTimer = setTimeout(() => {
      setMinTimeElapsed(true);
    }, 300);
    return () => clearTimeout(minTimeElapsedTimer);
  }, []);

  const findAssociationById = (obligationAssociations, detailedObligation) => {
    const activeAssociations = Object.values(
      obligationAssociations?.accounts?.active ?? {}
    ).flatMap(associations => associations || []);
    const inactiveAssociations = Object.values(
      obligationAssociations?.accounts?.inactive ?? {}
    ).flatMap(associations => associations || []);

    return (
      activeAssociations.find(
        association => association?.id === detailedObligation?.assocID
      ) ||
      inactiveAssociations.find(
        association => association?.id === detailedObligation?.assocID
      )
    );
  };

  useEffect(() => {
    const isInvalidObligation = (detailedObligation, pathSegments) => {
      return (
        detailedObligation === null ||
        detailedObligation === undefined ||
        detailedObligation?.config?.obligationSlug !==
          pathSegments[pathSegments.length - 1]
      );
    };
    /*
      Detailed obligaiton is set on the accounts page, so if the detailed obligation is invalid, we should navigate back to the accounts page.
    */
    if (isInvalidObligation(detailedObligation, pathSegments)) {
      navigate(topLevel);
      return;
    }

    const obligationAssociation = findAssociationById(
      obligationAssociations,
      detailedObligation
    );
    const isAssociationLoading =
      obligationAssociation && obligationAssociation.isLoading;
    setObligationAssociationState({
      isLoading: isAssociationLoading,
      failedToLoad: !isAssociationLoading && !!obligationAssociation?.error
    });

    // Check if there are any loading obligations
    const isDetailedObligationLoading = detailedObligation?.obligations?.some(
      obl => obl.isLoading
    );

    // If the association is not loading and the obligation is loading, set the detailed obligation
    // This corrects the issue when the detailed obligation is set on the accounts page before the obligation fetch is complete
    if (
      !isAssociationLoading &&
      isDetailedObligationLoading &&
      obligationAssociation &&
      !obligationAssociation.error
    ) {
      resourcesActions.setDetailedObligation(
        obligationAssociation.obligations,
        obligationAssociation.config,
        obligationAssociation.id
      );
    }
  }, [detailedObligation, obligationAssociations]);

  useEffect(() => {
    const { accountDetailsAlertBar: alertBar } = alertBars;
    const { failedToLoad } = obligationAssociationState;
    if (failedToLoad && R.isEmpty(alertBar.alerts)) {
      actions.alertBars.accountDetailsAlertBar.addAlert({
        heading: "There was a problem loading this page",
        text: "We were unable to load some of your account details.",
        variant: "error"
      });
    } else if (!failedToLoad && !R.isEmpty(alertBar.alerts)) {
      actions.alertBars.accountDetailsAlertBar.clearAlerts();
    }
  }, [obligationAssociationState]);

  const isPaymentPlan = topLevel.split("/")[2] === "properties";
  const planType = isPaymentPlan ? "Payment Plan" : "Autopay";
  const {
    createPaymentFromProfile,
    deactivatePaymentSchedule,
    deleteObligationAssoc,
    configureMultiCart,
    addToMultiCart,
    openCartSlider
  } = resourcesActions;

  const billURL = detailedObligation?.bill?.value?.url;
  const billURLLoading = detailedObligation?.bill?.isLoading;

  const { isMobile } = useContext(ThemeContext);
  const [toggleKeyList, setToggleKeyList] = useState([]);
  const [modalOpen, toggleModal] = useState(false);

  /* Customizing the content loading bar width with respect to the title and screen width */
  const loadingBarMinWidth = (isMobile, title) => {
    const isAddressTitle = title?.toLowerCase()?.includes("addresss");
    if (isMobile) return isAddressTitle ? "143" : "70";
    return isAddressTitle ? "190" : "92";
  };
  const loadingBarMaxWidth = "190";

  // Paperless Billing
  const showPaperlessBillingSection =
    detailedObligation?.config?.hasPaperlessBilling;
  const [paperlessBillingEnabled, setPaperlessBillingEnabled] = useState(false);
  const [
    showPaperlessBillingInfoModal,
    setShowPaperlessBillingInfoModal
  ] = useState(false);
  const [
    showPaperlessBillingConfirmModal,
    setShowPaperlessBillingConfirmModal
  ] = useState(false);
  const [isPaperlessBillingLoading, setIsPaperlessBillingLoading] = useState(
    false
  );

  // TODO: Eventually will need to filter based on agency
  //const obligations = Object.values(resources?.obligations ?? {});
  const { obligations = [], config = {}, assocID = "" } =
    detailedObligation ?? {};
  const details = config?.details ?? {};
  const contactLink = config?.contactLink ?? "";
  const contactText = config?.contactText ?? "Contact";
  const { achAccounts, addresses, creditCards, schedules } = resources;
  const getNextAutopayDate = getDueDate(schedules);
  const firstObligation = obligations?.[0] ?? {};
  const subClient = detailedObligation?.config?.obligationSlug;
  const subClientPaymentConfig =
    paymentConfigs?.[subClient] ?? paymentConfigs.default;
  const formattedAgencyName =
    subClientPaymentConfig?.options?.agencyDisplayName ??
    formatName(accountsAgency);
  const autoPayAvailable = R.and(
    R.or(R.not(R.isEmpty(achAccounts)), R.not(R.isEmpty(creditCards))),
    R.not(R.isEmpty(addresses))
  );
  const autoPayEnabled = isAutoPayEnabled(autoPayData, assocID);
  const totalAmountDue = obligations?.reduce(
    (acc, curr) => acc + curr.amountDue,
    0
  );
  const association = Object.values(
    obligationAssociations?.accounts?.active ?? []
  )
    .reduce((acc, associations) => acc.concat(associations || []), [])
    .find(
      association =>
        association && association.id === detailedObligation?.assocID
    );

  const { accountDetailsAlertBar: alertBar } = alertBars;
  const {
    alertBars: { accountDetailsAlertBar: alertBarActions }
  } = actions;

  // Pay (or Add to Cart) Button
  const isInCart = checkIsInCart(carts, association);
  const payButtonText = cartEnabled
    ? isInCart
      ? "In Cart"
      : "Add to Cart"
    : "Make Payment";
  const payButtonVariant = cartEnabled
    ? isInCart
      ? "greenPrimary"
      : "primary"
    : isMobile
    ? "smallPrimary"
    : "primary";

  const handlePayBtnClick = _event => {
    if (isInCustomerManagement) {
      return;
    }
    if (isInCart) {
      openCartSlider();
      return;
    }
    const lineItems = createLineItems(association);
    const cartConfig = createCartConfig(association, lineItems, paymentConfigs);
    if (cartEnabled) {
      configureMultiCart({ cartId: subClient, config: cartConfig });
      addToMultiCart({
        cartId: subClient,
        items: lineItems,
        itemType: STANDARD_ITEM_TYPE
      });
    } else {
      createPaymentFromProfile(obligations, config);
    }
  };

  const handleCollapsibleSection = index => {
    const newList = toggleKeyList.includes(index)
      ? toggleKeyList.filter(i => i !== index)
      : [...toggleKeyList, index];

    setToggleKeyList(newList);
  };

  const handleTitleFormatting = string =>
    string
      .split("_")
      .map(capitalize)
      .join(" ");

  const buildAutoPayDetails = (autoPayData, totalAmountDue) => {
    const oblAutoPayData = retrieveAutoPayData(autoPayData, assocID);
    const autoPayMethodType = oblAutoPayData?.paymentInstrument?.kind ?? "";
    const autoPayAccountType = [
      oblAutoPayData?.paymentInstrument?.accountType?.toLowerCase() ?? ""
    ].map(item => item.charAt(0).toUpperCase() + item.slice(1))[0];
    const autoPayMethodLastFour =
      oblAutoPayData?.paymentInstrument?.lastFour ?? "";
    const autoPayMethod =
      autoPayMethodType === "CREDIT_CARD"
        ? `Card ending in ${autoPayMethodLastFour}`
        : `${autoPayAccountType} Account ending in ${autoPayMethodLastFour}`;

    const autopayValues = [
      {
        key: "autopay_status",
        value: "on"
      },
      {
        key: "next_payment_amount",
        value: util.general.displayCurrency(totalAmountDue)
      },
      {
        key: "next_payment_date",
        value: getNextAutopayDate(assocID)
      },
      {
        key: "payment_method",
        value: autoPayMethod
      }
    ];

    return autopayValues.filter(attr =>
      attr.value === ""
        ? false
        : attr.key === "next_payment_date" && attr.value === "On"
        ? false
        : true
    );
  };

  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);
  const defaultStyles = `
  .autopayIcon { fill: ${constants.colors.SEA_GREEN}; text-decoration: underline; }
   `;

  const getOblDescription = obligation =>
    obligation?.details?.description ?? obligation.description;
  const getOblSubDescription = obligation =>
    obligation?.details?.subDescription ?? obligation.subDescription;

  const TopTitle = ({ obligation }) => (
    <Box
      padding={isMobile ? "20px 0 20px 16px" : "0"}
      background={constants.colors.WHITE}
    >
      <Stack childGap="0">
        <Heading
          variant="h6"
          weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
          color={constants.colors.BRIGHT_GREY}
          as="h6"
        >
          {getOblDescription(obligation)}
        </Heading>
        <Text color={constants.colors.STORM_GREY} variant="pS" as="h4">
          {getOblSubDescription(obligation)}
        </Text>
        <Text color={constants.colors.STORM_GREY} variant="pS" as="h4">
          <ContentWithLoader
            content={formattedAgencyName}
            isLoading={obligationAssociationState.isLoading}
            failedToLoad={obligationAssociationState.failedToLoad}
          />
        </Text>
      </Stack>
    </Box>
  );
  const CollapsibleSectionTitle = ({
    isMobile,
    obligation,
    autoPayEnabled,
    nextAutopayDate
  }) => (
    <Box padding={isMobile ? "20px 0 20px 16px" : "16px 0 16px 24px"}>
      <Box padding="0" minWidth="93%">
        <Cluster
          justify={isMobile ? "flex-start" : "space-between"}
          align="center"
        >
          {!isMobile && <TopTitle obligation={obligation} />}
          <Box padding="0">
            <Stack
              childGap="0"
              style={{ alignItems: isMobile ? "flex-start" : "flex-end" }}
            >
              {isMobile && (
                <Text
                  fontWeight={constants.fontWeights.FONT_WEIGHT_BOLD}
                  color={constants.colors.CHARADE_GREY}
                  variant="pS"
                >
                  Amount Due
                </Text>
              )}
              <ContentWithLoader
                content={
                  <AmountCallout
                    variant={isMobile ? "small" : "medium"}
                    amount={util.general.displayCurrency(obligation.amountDue)}
                    textAlign={isMobile ? "left" : "right"}
                  />
                }
                isLoading={obligationAssociationState.isLoading}
                failedToLoad={obligationAssociationState.failedToLoad}
                height={isMobile ? "22px" : "24px"}
                minWidth={"67"}
                maxWidth={"67"}
                margin={"3px"}
              />
              <ContentWithLoader
                content={
                  <>
                    {autoPayEnabled && (
                      <Box padding="0" extraStyles={defaultStyles}>
                        <Cluster justify="flex-start" align="center">
                          <AutopayOnIcon />
                          <Text
                            variant="pS"
                            color={constants.colors.SEA_GREEN}
                            extraStyles={`padding-left: 0.25rem;`}
                          >
                            Autopay {nextAutopayDate}
                          </Text>
                        </Cluster>
                      </Box>
                    )}
                  </>
                }
                isLoading={obligationAssociationState.isLoading}
                failedToLoad={obligationAssociationState.failedToLoad}
                minWidth={isMobile ? "70" : "93"}
                maxWidth={isMobile ? "70" : "93"}
              />
            </Stack>
          </Box>
        </Cluster>
      </Box>
    </Box>
  );

  if (!minTimeElapsed) {
    return <Loading />;
  }

  return (
    <Box padding={isMobile ? "0 0 16px" : "0 4px 16px"}>
      <Stack childGap={isMobile ? "16px" : "24px"}>
        {!R.isEmpty(alertBar.alerts) && (
          <Box padding="1rem 0 0">
            <AlertBar {...alertBar} {...alertBarActions} key="alert-bar" />
          </Box>
        )}
        <Box padding={0}>
          <Stack fullHeight childGap="24px">
            <Box
              background={constants.colors.WHITE}
              borderRadius="4px"
              boxShadow={`0px 0px 5px 0px ${constants.colors.GHOST_GREY}`}
              padding="0"
            >
              <Box
                padding="1rem 1.5rem"
                background={constants.colors.ATHENS_GREY}
                boxShadow={`0px 1px 0px 0px ${constants.colors.GHOST_GREY}`}
              >
                <Stack childGap="0">
                  <Heading
                    variant="h6"
                    weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
                    color={constants.colors.BRIGHT_GREY}
                    as="h1"
                  >
                    {firstObligation.description}
                  </Heading>
                  <Text
                    variant="pS"
                    fontColor={constants.colors.STORM_GREY}
                    as="h2"
                  >
                    {firstObligation.subDescription}
                  </Text>

                  <Text
                    variant="pS"
                    fontColor={constants.colors.STORM_GREY}
                    as="h2"
                    dataQa="agency-display-name"
                  >
                    <ContentWithLoader
                      content={formattedAgencyName}
                      isLoading={obligationAssociationState.isLoading}
                      failedToLoad={obligationAssociationState.failedToLoad}
                    />
                  </Text>
                </Stack>
              </Box>
              <Box padding={isMobile ? "16px 8px" : "24px"}>
                <Stack childGap="24px">
                  <Switcher
                    breakpoint="30rem"
                    childGap="1rem"
                    largeChild={isMobile ? "1" : ""}
                    largeChildSize={isMobile ? "2" : "1"}
                  >
                    <Box
                      padding={isMobile ? "1rem" : "1.5rem"}
                      background={constants.colors.ATHENS_GREY}
                      borderRadius="2px"
                      boxShadow={`0px 0px 5px 0px ${constants.colors.GHOST_GREY}`}
                    >
                      <Cover minHeight="100%" singleChild>
                        <Switcher
                          childGap={isMobile ? "8px" : "24px"}
                          breakpoint="5rem"
                          extraStyles="align-items:center;"
                        >
                          <Box padding="0">
                            <Center intrinsic>
                              <Stack childGap="0">
                                <Center intrinsic>
                                  <Text
                                    variant="pS"
                                    weight={
                                      constants.fontWeights.FONT_WEIGHT_SEMIBOLD
                                    }
                                    fontColor={constants.colors.CHARADE_GREY}
                                  >
                                    {isMobile
                                      ? "Total Due"
                                      : "Total Amount Due"}
                                  </Text>
                                </Center>
                                <Box padding="0">
                                  <Center intrinsic>
                                    <ContentWithLoader
                                      content={
                                        <AmountCallout
                                          variant="medium"
                                          amount={util.general.displayCurrency(
                                            totalAmountDue
                                          )}
                                        />
                                      }
                                      isLoading={
                                        obligationAssociationState.isLoading
                                      }
                                      failedToLoad={
                                        obligationAssociationState.failedToLoad
                                      }
                                      height={isMobile ? "22px" : "26px"}
                                      maxWidth={"67"}
                                      minWidth={isMobile ? "22" : "67"}
                                    />
                                  </Center>
                                </Box>
                              </Stack>
                            </Center>
                          </Box>
                          <Center intrinsic>
                            <ButtonWithAction
                              action={handlePayBtnClick}
                              text={payButtonText}
                              variant={payButtonVariant}
                              disabled={
                                isInCustomerManagement ||
                                obligationAssociationState.isLoading ||
                                obligationAssociationState.failedToLoad
                              }
                            />
                          </Center>
                        </Switcher>
                      </Cover>
                    </Box>
                    <Box
                      padding={isMobile ? "1rem" : "1.5rem"}
                      background={constants.colors.ATHENS_GREY}
                      borderRadius="2px"
                      boxShadow={`0px 0px 5px 0px ${constants.colors.GHOST_GREY}`}
                    >
                      <Cover minHeight="100%" singleChild>
                        <Box minWidth="100%" padding="0">
                          <Center intrinsic>
                            <Box minWidth="100%" padding="0">
                              <Stack childGap="0">
                                <Box padding="0" textAlign="center">
                                  <Text
                                    variant="pS"
                                    weight={
                                      constants.fontWeights.FONT_WEIGHT_SEMIBOLD
                                    }
                                    color={constants.colors.CHARADE_GREY}
                                  >
                                    {isMobile ? planType : `${planType} Status`}
                                  </Text>
                                </Box>
                                {autoPayAvailable && !autoPayEnabled ? (
                                  <ButtonWithAction
                                    text={`Set Up ${planType}`}
                                    variant="ghost"
                                    action={() => {
                                      isInCustomerManagement
                                        ? util.general.noop
                                        : isPaymentPlan
                                        ? navigate(`${topLevel}/payment-plan`)
                                        : navigate(
                                            `${topLevel}/set-up-autopay`
                                          );
                                    }}
                                    dataQa="Set Up Autopay"
                                    extraStyles={`padding: 0; min-height: auto;`}
                                    textExtraStyles={
                                      isMobile
                                        ? `font-size: 1.094rem; line-height: 1.641rem;`
                                        : `font-size: 1.25rem; line-height: 1.875rem;`
                                    }
                                    disabled={
                                      isInCustomerManagement ||
                                      obligationAssociationState.isLoading ||
                                      obligationAssociationState.failedToLoad
                                    }
                                  />
                                ) : (
                                  <AccountDetailsModal
                                    autoPayActive={autoPayEnabled}
                                    autoPaySchedule={
                                      retrieveAutoPayData(autoPayData, assocID)
                                        .id
                                    }
                                    paymentPlanSchedule={paymentPlanData.id}
                                    toggleModal={toggleModal}
                                    modalOpen={modalOpen}
                                    modalDestination={"/profile/wallet"}
                                    deactivatePaymentSchedule={
                                      deactivatePaymentSchedule
                                    }
                                    isMobile={isMobile}
                                    isPaymentPlan={isPaymentPlan}
                                    nextAutopayDate={getNextAutopayDate(
                                      assocID
                                    )}
                                    disabled={
                                      isInCustomerManagement ||
                                      obligationAssociationState.isLoading ||
                                      obligationAssociationState.failedToLoad
                                    }
                                  />
                                )}
                              </Stack>
                            </Box>
                          </Center>
                        </Box>
                      </Cover>
                    </Box>
                  </Switcher>
                  {/* Obligations Details list */}
                  {obligations &&
                    !R.isEmpty(obligations) &&
                    obligations.map((obligation, index) => {
                      return (
                        <Box
                          key={obligation.id}
                          boxShadow={`0px 0px 5px 0px ${constants.colors.GHOST_GREY}`}
                          borderRadius={isMobile ? "2px" : "4px"}
                          padding="0"
                          background={
                            isMobile
                              ? constants.colors.ATHENS_GREY
                              : constants.colors.WHITE
                          }
                          width="100%"
                        >
                          <CollapsibleSection
                            title={
                              <CollapsibleSectionTitle
                                isMobile={isMobile}
                                obligation={obligation}
                                autoPayEnabled={autoPayEnabled}
                                nextAutopayDate={getNextAutopayDate(assocID)}
                              />
                            }
                            customPadding={
                              isMobile ? "0 16px 0 0" : "0 24px 0 0"
                            }
                            customTitle
                            toggleSection={() =>
                              handleCollapsibleSection(index)
                            }
                            isOpen={!toggleKeyList.includes(index)}
                            isMobile={isMobile}
                            stackTitle={isMobile}
                            stackTitleContent={
                              <TopTitle obligation={obligation} />
                            }
                            name={`${getOblDescription(
                              obligation
                            )} ${getOblSubDescription(obligation)}`}
                            index={index}
                          >
                            <Motion
                              padding="0"
                              transition={{ duration: 0.3 }}
                              positionTransition
                              extraStyles={`transform-origin: 100% 0;`}
                            >
                              <Box
                                padding={isMobile ? "0 0.5rem" : "0 1rem"}
                                background={constants.colors.ATHENS_GREY}
                                borderRadius="0 0 4px 4px"
                              >
                                <EditableTable
                                  isMobile={isMobile}
                                  items={obligation}
                                  renderItem={obligationItem => {
                                    if (obligationItem?.customAttributes) {
                                      return (
                                        <Box padding="0" minWidth="100%">
                                          <Stack childGap="0">
                                            {Object.entries(
                                              obligationItem?.customAttributes
                                            )
                                              .filter(item =>
                                                Object.values(
                                                  details.obligationDetails
                                                ).includes(item[0])
                                              )
                                              .map(filteredAttribute => {
                                                // Extract obligation details entries
                                                const obligationEntries = Object.entries(
                                                  details.obligationDetails
                                                );
                                                // Filter entries based on the filtered attribute
                                                const filteredEntries = obligationEntries.filter(
                                                  item =>
                                                    item[1] ===
                                                    filteredAttribute[0]
                                                );
                                                // Get the first matching entry's key
                                                const firstMatchingKey =
                                                  filteredEntries[0][0];
                                                // Format the title using the first matching key
                                                const obligationItemTitle = handleTitleFormatting(
                                                  firstMatchingKey
                                                );
                                                const obligationItemValue = handleTitleFormatting(
                                                  filteredAttribute[1]
                                                );
                                                // Always show the obligation subDescription value
                                                // Otherwise, show the loading bar if the obligation is loading
                                                const isLoading =
                                                  !(
                                                    obligationItemValue?.length &&
                                                    firstObligation.subDescription?.includes(
                                                      obligationItemValue
                                                    )
                                                  ) &&
                                                  obligationAssociationState.isLoading;
                                                return (
                                                  <TableListItem
                                                    value={
                                                      <ContentWithLoader
                                                        content={
                                                          obligationItemValue
                                                        }
                                                        isLoading={isLoading}
                                                        failedToLoad={
                                                          obligationAssociationState.failedToLoad
                                                        }
                                                        maxWidth={
                                                          loadingBarMaxWidth
                                                        }
                                                        minWidth={loadingBarMinWidth(
                                                          isMobile,
                                                          obligationItemTitle
                                                        )}
                                                      />
                                                    }
                                                    title={obligationItemTitle}
                                                    key={
                                                      obligationItemTitle +
                                                      filteredAttribute[1]
                                                    }
                                                    isMobile={isMobile}
                                                    borderTopItem
                                                  />
                                                );
                                              })}
                                          </Stack>
                                        </Box>
                                      );
                                    }
                                  }}
                                />
                              </Box>
                              <Box
                                background={constants.colors.ATHENS_GREY}
                                padding="0 16px"
                              >
                                <Box
                                  background={constants.colors.ATHENS_GREY}
                                  padding="20px 0"
                                  borderColor={constants.colors.GHOST_GREY}
                                  borderSize="1px"
                                  borderWidthOverride={"1px 0 0 0"}
                                >
                                  <Cluster align="center" justify="flex-end">
                                    <ButtonWithLink
                                      variant="secondary"
                                      url={billURL}
                                      fileLink
                                      dataQa="view-bill-button"
                                      newTab={true}
                                      text={
                                        config.details?.pdfButtonText ??
                                        "View Bill"
                                      }
                                      disabled={
                                        obligationAssociationState.isLoading ||
                                        billURLLoading ||
                                        obligationAssociationState.failedToLoad
                                      }
                                    />
                                  </Cluster>
                                </Box>
                              </Box>
                            </Motion>
                          </CollapsibleSection>
                        </Box>
                      );
                    })}
                </Stack>
              </Box>
            </Box>
            {!obligationAssociationState.isLoading &&
              !obligationAssociationState.failedToLoad &&
              autoPayEnabled && (
                <Box padding="0">
                  {/* Autopay Details */}
                  <Stack>
                    <Box padding="0">
                      <Paragraph
                        variant="pL"
                        weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
                        color={constants.colors.BRIGHT_GREY}
                        as="h2"
                      >
                        {`${planType} Details`}
                      </Paragraph>
                    </Box>
                    <Box
                      padding="0 16px"
                      background={constants.colors.WHITE}
                      borderRadius={isMobile ? "2px" : "4px"}
                      boxShadow={`0px 0px 5px 0px ${constants.colors.GHOST_GREY}`}
                    >
                      {/* SRV-2239: Temporarily setting the autopay details to always look at autoPayData in state instead of paymentPlanData, even for properties. Need to fix this for real proprety tax support */}
                      <EditableTable
                        isMobile={isMobile}
                        items={buildAutoPayDetails(
                          //isPaymentPlan ? paymentPlanData : autoPayData,
                          autoPayData,
                          totalAmountDue
                        )}
                        renderItem={x => (
                          <Box padding="0" minWidth="100%">
                            <Stack childGap="0">
                              {x.map(attribute => (
                                <TableListItem
                                  value={handleTitleFormatting(attribute.value)}
                                  title={handleTitleFormatting(attribute.key)}
                                  key={attribute.value}
                                  isMobile={isMobile}
                                />
                              ))}
                            </Stack>
                          </Box>
                        )}
                      />
                    </Box>
                  </Stack>
                </Box>
              )}
            {/* Paperless Billing */}
            {!obligationAssociationState.isLoading &&
              !obligationAssociationState.failedToLoad &&
              showPaperlessBillingSection && (
                <Box padding="8px 0 0 0">
                  <Stack>
                    <Stack
                      direction="row"
                      justify="space-between"
                      align="center"
                    >
                      <Paragraph
                        variant="pL"
                        weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
                        color={constants.colors.BRIGHT_GREY}
                        as="h2"
                      >
                        Paperless Billing
                      </Paragraph>
                      <ButtonWithAction
                        aria-label="Learn more about Paperless Billing"
                        aria-haspopup="dialog"
                        text="What's this?"
                        action={() => setShowPaperlessBillingInfoModal(true)}
                        dataQa="Paperless Billing Info Link"
                        variant="smallGhost"
                        extraStyles="margin: 0; min-height: auto; min-width: auto;"
                        textExtraStyles="font-size: 0.875rem;"
                      />
                    </Stack>
                    <Box
                      padding="0 16px"
                      background={constants.colors.WHITE}
                      borderRadius={isMobile ? "2px" : "4px"}
                      boxShadow={`0px 0px 5px 0px ${constants.colors.GHOST_GREY}`}
                    >
                      <Stack
                        direction="row"
                        justify="space-between"
                        align="center"
                        childGap={isMobile ? "1.5rem" : "0.5rem"}
                        style={{
                          padding: isMobile
                            ? "1.313rem 0.313rem"
                            : "1.5rem 0.5rem",
                          alignItems: "center"
                        }}
                        role="group"
                      >
                        <Paragraph
                          variant={isMobile ? "pS" : "p"}
                          as="span"
                          id="paperless-billing-description"
                        >
                          {paperlessBillingEnabled
                            ? "Paperless billing is on. You will not receive paper bills."
                            : "Paperless billing is off. You will receive paper bills."}
                        </Paragraph>
                        <ToggleSwitch
                          ariaDescribedBy="paperless-billing-description"
                          dataQa="paperless-billing-toggle"
                          label={paperlessBillingEnabled ? "On" : "Off"}
                          labelLeft="true"
                          labelStyles={`font-size: ${
                            isMobile ? "1.125rem" : "1.25rem"
                          }`}
                          isOn={paperlessBillingEnabled}
                          name="Paperless Billing"
                          onToggle={() =>
                            isInCustomerManagement
                              ? util.general.noop
                              : setShowPaperlessBillingConfirmModal(true)
                          }
                          isMobile={isMobile}
                          minWidth={isMobile ? "75px" : "84px"}
                          extraStyles={`padding: 0; margin: 0;`}
                        ></ToggleSwitch>
                      </Stack>
                    </Box>
                  </Stack>
                  <PaperlessBillingInfoModal
                    agencyName={formattedAgencyName}
                    modalContent={parse(
                      dompurify.sanitize(
                        detailedObligation?.config?.paperlessBilling
                          ?.infoContent
                      )
                    )}
                    modalIsOpen={showPaperlessBillingInfoModal}
                    setModalIsOpen={setShowPaperlessBillingInfoModal}
                  />
                  <PaperlessBillingConfirmModal
                    agencyName={formattedAgencyName}
                    isLoading={isPaperlessBillingLoading}
                    setIsLoading={setIsPaperlessBillingLoading}
                    modalContent={
                      paperlessBillingEnabled
                        ? parse(
                            dompurify.sanitize(
                              detailedObligation?.config?.paperlessBilling
                                ?.cancelContent
                            )
                          )
                        : parse(
                            dompurify.sanitize(
                              detailedObligation?.config?.paperlessBilling
                                ?.confirmationContent
                            )
                          )
                    }
                    modalIsOpen={showPaperlessBillingConfirmModal}
                    setModalIsOpen={setShowPaperlessBillingConfirmModal}
                    setPaperlessBillingEnabled={setPaperlessBillingEnabled}
                    paperlessBillingEnabled={paperlessBillingEnabled}
                  />
                </Box>
              )}
            {/* Account Details */}
            {obligations && !R.isEmpty(obligations) && (
              <Box padding="8px 0 0 0">
                <Stack childGap="0.5rem">
                  <Box padding="0">
                    <Paragraph
                      variant="pL"
                      weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
                      color={constants.colors.BRIGHT_GREY}
                      as="h2"
                    >
                      Account Details
                    </Paragraph>
                  </Box>
                  <Box
                    padding="0 16px"
                    background={constants.colors.WHITE}
                    borderRadius={isMobile ? "2px" : "4px"}
                    boxShadow={`0px 0px 5px 0px ${constants.colors.GHOST_GREY}`}
                  >
                    <EditableTable
                      isMobile={isMobile}
                      items={firstObligation}
                      renderItem={x => (
                        <Box padding="0" minWidth="100%">
                          <Stack childGap="0">
                            {details.collectionDetailsList
                              ? details.collectionDetailsList.map(
                                  itemDetail => {
                                    const { label, attribute } = itemDetail;
                                    if (x.customAttributes) {
                                      const itemValue = handleTitleFormatting(
                                        x.customAttributes[attribute] ?? ""
                                      );
                                      // Always show the obligation subDescription value
                                      // Otherwise, show the loading bar if the obligation is loading
                                      const isLoading =
                                        !(
                                          itemValue?.length &&
                                          firstObligation.subDescription?.includes(
                                            itemValue
                                          )
                                        ) &&
                                        obligationAssociationState.isLoading;
                                      return (
                                        <TableListItem
                                          value={
                                            <ContentWithLoader
                                              content={itemValue}
                                              isLoading={isLoading}
                                              failedToLoad={
                                                obligationAssociationState.failedToLoad
                                              }
                                              maxWidth={loadingBarMaxWidth}
                                              minWidth={loadingBarMinWidth(
                                                isMobile,
                                                itemValue
                                              )}
                                            />
                                          }
                                          title={handleTitleFormatting(label)}
                                          key={label + itemValue}
                                          isMobile={isMobile}
                                        />
                                      );
                                    }
                                  }
                                )
                              : Object.entries(x.customAttributes)
                                  .filter(item =>
                                    Object.values(
                                      details.collectionDetails
                                    ).includes(item[0])
                                  )
                                  .map(filteredAttribute => {
                                    // Extract collection details entries
                                    const collectionEntries = Object.entries(
                                      details.collectionDetails
                                    );
                                    // Filter entries based on the filtered attribute
                                    const filteredEntries = collectionEntries.filter(
                                      item => item[1] === filteredAttribute[0]
                                    );
                                    // Get the first matching entry's key
                                    const firstMatchingKey =
                                      filteredEntries[0][0];
                                    // Format the title using the first matching key
                                    const formattedTitle = handleTitleFormatting(
                                      firstMatchingKey
                                    );
                                    const itemValue = handleTitleFormatting(
                                      filteredAttribute[1]
                                    );
                                    // Always show the obligation subDescription
                                    // Otherwise, show the loading bar if the obligation is loading
                                    const isLoading =
                                      !(
                                        itemValue?.length &&
                                        firstObligation.subDescription?.includes(
                                          itemValue
                                        )
                                      ) && obligationAssociationState.isLoading;
                                    return (
                                      <TableListItem
                                        value={
                                          <ContentWithLoader
                                            content={itemValue}
                                            isLoading={isLoading}
                                            failedToLoad={
                                              obligationAssociationState.failedToLoad
                                            }
                                            maxWidth={loadingBarMaxWidth}
                                            minWidth={loadingBarMinWidth(
                                              isMobile
                                            )}
                                          />
                                        }
                                        title={formattedTitle}
                                        key={formattedTitle + itemValue}
                                        isMobile={isMobile}
                                      />
                                    );
                                  })}
                          </Stack>
                        </Box>
                      )}
                    />
                    <Box
                      padding={isMobile ? "16px 0" : "24px 0"}
                      borderSize="1px"
                      borderColor={constants.colors.GHOST_GREY}
                      borderWidthOverride="1px 0 0 0"
                    >
                      <Cluster
                        justify="flex-start"
                        align="center"
                        childGap="1.5rem"
                      >
                        <ButtonWithLink
                          url={contactLink}
                          newTab={true}
                          text={contactText}
                          variant="secondary"
                          disabled={isInCustomerManagement}
                        />
                        {canRemoveObligation && (
                          <RemoveAccountModal
                            agency={firstObligation.description}
                            obligations={obligations}
                            deleteObligationAssoc={deleteObligationAssoc}
                            assocID={assocID}
                            accountType={
                              config.type === "ACCOUNT" ? "Account" : "Property"
                            }
                            isMobile={isMobile}
                            detailedObligation={detailedObligation}
                            disabled={isInCustomerManagement}
                          />
                        )}
                      </Cluster>
                    </Box>
                  </Box>
                </Stack>
              </Box>
            )}
          </Stack>
        </Box>
      </Stack>
    </Box>
  );
};

export default connect(null)(withWindowSize(React.memo(AccountDetails)));

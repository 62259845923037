import React, {
  useContext,
  useState,
  useEffect,
  Fragment,
  Suspense
} from "react";
import styled from "styled-components";
import { Router, useRoutes, useNavigate } from "react-router-dom";
import { matchRoutes } from "react-router";
import {
  ThemeProvider,
  createGlobalStyle,
  ThemeContext
} from "styled-components";
import { Provider as ReduxProvider, connect } from "react-redux";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import { Security } from "@okta/okta-react";
import HashLinkObserver from "./util/hashLinkObserver";
import createNavStore, { history } from "./state/store";
import { ERROR, LOADING, SUCCESS, NOT_ASKED } from "./util/remoteData";
import { buildRoutes, flattenRoutes } from "./util/router-utils";
import {
  adminLogout,
  logout,
  setLaunchDarklyContext,
  setLaunchDarklyFlags
} from "./state/reducer";
import NotFound from "./apps/common/pages/not-found";
import ErrorPage from "./apps/common/pages/error";
import LoadingPage from "./apps/common/pages/loading";
import LoadingSuspense from "./apps/common/pages/loading-suspense";
import Redirect from "./apps/common/pages/redirect";
import CMS from "./apps/web/pages/cms";
import Header from "./components/header";
import Footer from "./components/footer";
import * as R from "ramda";
import { routes } from "./routes";
import { getAccessToken, getAdminAccessToken, getOIDC } from "./util/state";
import { themeComponent } from "./util/themeUtils";
import { withWindowSize, util } from "@thecb/components";
import IdleModal from "./components/idle-modal";
import { useIdleTimer } from "react-idle-timer";
import useLogoutTimers from "./util/idleTimerUtils";
import { checkoutIdlePaths } from "./apps/checkout/pages/payment/constants";
import { initiateExpiredSession } from "./apps/checkout/pages/payment/Payment.state";
import useGoogleAnalytics from "./util/useGoogleAnalytics";
import ErrorBoundary from "./components/error-boundary";
import { useFlags, useLDClient } from "launchdarkly-react-client-sdk";
import {
  ACCOUNT_LOADING_EXPERIENCE_FLAG,
  WALLET_SCREEN_V2_FLAG
} from "./util/launchDarklyUtils";

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const RoutesComponent = ({ clientRoutes }) => useRoutes(clientRoutes);

const RouterLoading = () => <LoadingPage />;

const RouterError = () => <ErrorPage />;

const RouterSuccess = ({
  authToken,
  adminAuthToken,
  onLogout,
  onAdminLogout,
  onExpireSession,
  clientSettings,
  workflows,
  workflowMetadata,
  clientMetadata,
  subClientMetadata,
  localStorageItems,
  profileId,
  paymentComplete,
  oidc,
  setLaunchDarklyFlags,
  setLaunchDarklyContext
}) => {
  const [idleModalOpen, toggleIdleModal] = useState(false);
  const [logoutTimerOn, toggleLogoutTimer] = useState(false);
  const [timeLeftToLogout, setTimeLeft] = useState(30);
  const [focusErrors, handleFocusErrors] = useState(false);
  const ldClient = useLDClient();
  const ldFlags = useFlags();
  const { useFocusInvalidInput } = util;
  const isLoggedIn = authToken !== null && authToken !== undefined;
  const isAuthenticatedAdmin =
    adminAuthToken !== null && adminAuthToken !== undefined;
  const obligationsConfig = clientSettings?.data?.obligations ?? [];
  const canAddObligation = clientSettings?.data?.canAddObligation ?? false;
  const canRemoveObligation =
    clientSettings?.data?.canRemoveObligation ?? false;
  const hasPaymentFlag = clientSettings?.data?.hasPaymentFlag ?? false;
  const welcomeCards = clientSettings?.data?.welcomeCards ?? [];
  const userFirstName = localStorageItems?.firstName;
  const userLastName = localStorageItems?.lastName;
  const walletEnabled = clientSettings?.data?.walletEnabled ?? false;
  const { clientTrackingId, trackingId } =
    clientMetadata?.data?.analytics ?? {};
  const walletScreenV2Enabled = ldFlags[WALLET_SCREEN_V2_FLAG];
  const accountLoadingExperienceEnabled =
    ldFlags[ACCOUNT_LOADING_EXPERIENCE_FLAG];
  const payDotEnabled = clientSettings?.data?.payDotEnabled ?? false;
  const props = {
    authToken,
    onLogout,
    onAdminLogout,
    isLoggedIn,
    isAuthenticatedAdmin,
    showLogout: isLoggedIn || isAuthenticatedAdmin,
    workflows,
    workflowMetadata,
    obligationsConfig,
    clientMetadata,
    subClientMetadata,
    canAddObligation,
    canRemoveObligation,
    hasPaymentFlag,
    welcomeCards,
    userFirstName,
    userLastName,
    isAuthenticatedAdmin,
    profileId,
    walletEnabled,
    oidc,
    handleFocusErrors,
    walletScreenV2Enabled,
    accountLoadingExperienceEnabled,
    payDotEnabled
  };
  const routesConfig = clientSettings?.data?.routes ?? [];
  const landingPage = clientSettings?.data?.landingPage
    ? clientSettings?.data?.landingPage
    : payDotEnabled
    ? "/"
    : "/login";
  const flatRoutes = flattenRoutes(routesConfig);
  const checkRoutePath = path => path === "/:pageSlug";
  const redirectRoute = flatRoutes.some(checkRoutePath)
    ? { path: "/", element: <CMS {...props} /> }
    : { path: "/", element: <Redirect redirectTo={landingPage} /> };
  const clientRoutes = [
    ...buildRoutes(flatRoutes, routes, props),
    redirectRoute,
    {
      path: "*",
      element: (
        <Fragment>
          <Header
            key="main-header"
            isLoggedIn={isLoggedIn}
            onLogout={onLogout}
          />
          <NotFound {...props} history={history} />
          <Footer
            key="main-footer"
            showLogout={isLoggedIn}
            onLogout={onLogout}
          />
        </Fragment>
      )
    }
  ];
  // Google Analytics internal tracking
  useEffect(() => {
    // validate tracking ID is in GA 4 format
    if (trackingId && trackingId.charAt(0) === "G") {
      useGoogleAnalytics(trackingId);
    }
  }, [trackingId]);

  // Google Analytics external client tracking
  useEffect(() => {
    // validate tracking ID is in GA 4 format
    if (clientTrackingId && clientTrackingId.charAt(0) === "G") {
      useGoogleAnalytics(clientTrackingId);
    }
  }, [clientTrackingId]);

  const currentLocation = window.location;
  const checkoutIdleTestPaths = checkoutIdlePaths.map(path => ({ path }));
  const onCheckoutIdlePath =
    matchRoutes(checkoutIdleTestPaths, currentLocation) !== null;

  const idleLogout = () => {
    window.clearInterval(logoutTimers.interval);
    toggleIdleModal(false);
    toggleLogoutTimer(false);
    // If payment is complete, we only want to log a user out, rather than expire the checkout session
    if (onCheckoutIdlePath && !paymentComplete) {
      onExpireSession();
    }
    if (isLoggedIn) {
      onLogout();
    }
    setTimeLeft(30);
  };

  const handleOnIdle = () => {
    // Guest checkout users who have completed a payment do not see idle modal
    if (!isLoggedIn && paymentComplete) {
      return;
    }
    // Logged in users, or users who have a checkout in progress see the idle modal
    if (isLoggedIn || onCheckoutIdlePath) {
      toggleIdleModal(true);
      toggleLogoutTimer(true);
    }
    return;
  };

  useIdleTimer({
    timeout: 1000 * 60 * 15,
    onIdle: handleOnIdle,
    debounce: 500
  });

  const logoutTimers = useLogoutTimers({
    logoutTimerOn,
    action: idleLogout,
    timeLeftToLogout,
    handleSetTimeLeft: setTimeLeft
  });

  useFocusInvalidInput(focusErrors, handleFocusErrors);

  const clientSlug = clientMetadata?.data?.slug;
  useEffect(() => {
    if (clientSlug) {
      const currentContext = ldClient.getContext();
      const clientContext = {
        client: {
          key: "client",
          slug: clientSlug
        }
      };
      const context = { ...currentContext, ...clientContext };
      ldClient.identify(context);
      setLaunchDarklyContext(context);
    }
  }, [clientSlug]);

  useEffect(() => {
    setLaunchDarklyFlags(ldFlags);
  }, [ldFlags]);

  return (
    <PageContainer>
      <Router history={history}>
        <Suspense fallback={<LoadingSuspense />}>
          <OktaSecurityWrapper oidc={oidc}>
            <HashLinkObserver />
            <RoutesComponent clientRoutes={clientRoutes} />
            <IdleModal
              modalOpen={idleModalOpen}
              toggleModal={toggleIdleModal}
              timeLeftToLogout={timeLeftToLogout}
              logout={idleLogout}
              cancelLogout={() => {
                window.clearInterval(logoutTimers.interval);
                window.clearTimeout(logoutTimers.timer);
                setTimeLeft(30);
                toggleIdleModal(false);
                toggleLogoutTimer(false);
              }}
              useCheckoutContent={!isLoggedIn}
            />
          </OktaSecurityWrapper>
        </Suspense>
      </Router>
    </PageContainer>
  );
};

const OktaSecurityWrapper = ({ oidc, children }) => {
  const navigate = useNavigate();
  const oktaAuth = new OktaAuth({
    clientId: "0oabd19hmh4y7dR8Z5d7",
    issuer: "https://auth.thecitybase.com/oauth2/default",
    ...oidc,
    redirectUri: window.location.origin + "/admin/login/okta/callback"
  });
  const restoreOriginalUri = (_oktaAuth, originalUri) => {
    navigate(toRelativeUrl(originalUri || "/", window.location.origin));
  };

  return (
    <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>
      {children}
    </Security>
  );
};

// Global styles
// Base font size controls base for all text element rem values
const GlobalStyles = createGlobalStyle`
  :root {
    font-size: ${props =>
      props.isMobile
        ? props.themeValues.baseMobileFontSize
        : props.themeValues.baseFontSize};
  }
`;

const GlobalStylesWrapper = withWindowSize(({ themeValues }) => {
  const themeContext = useContext(ThemeContext);
  const { isMobile } = themeContext;
  return <GlobalStyles themeValues={themeValues} isMobile={isMobile} />;
});

const globalStylesFallbackValues = {
  baseFontSize: "16px",
  baseMobileFontSize: "14px"
};

const ThemedGlobalStyles = themeComponent(
  GlobalStylesWrapper,
  "Global",
  globalStylesFallbackValues
);

// Styles that only affect workflows until old renderer/components are deprecated
// These CSS variables are used within the workflow css file
const WorkflowGlobalStyles = createGlobalStyle`
  :root {
    --primary-button-color: ${props => props.themeValues.primaryButtonColor};
    --secondary-button-color: ${props =>
      props.themeValues.secondaryButtonColor};
    --accent-color: ${props => props.themeValues.accent};
    --workflow-header-color: ${props => props.themeValues.workflowHeaderColor};
    --workflow-footer-color: ${props => props.themeValues.workflowFooterColor};
    --workflow-text-color: ${props => props.themeValues.textColor};
    --workflow-link-color: ${props => props.themeValues.linkColor};
    --workflow-font-family: ${props => props.themeValues.fontFamily};
  }
`;

const WorkflowGlobalStylesWrapper = ({ themeValues }) => (
  <WorkflowGlobalStyles themeValues={themeValues} />
);

const workflowGlobalFallbackValues = {
  primaryButtonColor: "#15749D",
  secondaryButtonColor: "#15749D",
  accent: "#0E506D",
  workflowHeaderColor: "#3b414d;",
  workflowFooterColor: "#3b414d",
  textColor: "#292A33",
  linkColor: "#15749D",
  fontFamily: "Public Sans, sans-serif"
};

const ThemedWorkflowGlobalStyles = themeComponent(
  WorkflowGlobalStylesWrapper,
  "Workflow",
  workflowGlobalFallbackValues
);

const AppPhaseSwitch = R.cond([
  [R.any(R.isNil), R.always(() => <RouterLoading />)],
  [
    R.any(R.either(R.propEq("phase", LOADING), R.propEq("phase", NOT_ASKED))),
    R.always(() => <RouterLoading />)
  ],
  [
    R.any(R.propEq("phase", ERROR)),
    R.always(({ appConfig }) => (
      <ErrorBoundary
        airbrakeProjectId={appConfig?.airbrakeProjectId ?? ""}
        airbrakeProjectKey={appConfig?.airbrakeProjectKey ?? ""}
        appEnv={appConfig?.environment}
      >
        <RouterError />
      </ErrorBoundary>
    ))
  ],
  [
    R.all(R.propEq("phase", SUCCESS)),
    R.always(
      ({
        authToken,
        adminAuthToken,
        appConfig,
        onLogout,
        onAdminLogout,
        onExpireSession,
        locationHistory,
        theme,
        clientMetadata,
        subClientMetadata,
        clientSettings,
        workflows,
        workflowMetadata,
        localStorageItems,
        profileId,
        paymentComplete,
        oidc,
        setLaunchDarklyFlags,
        setLaunchDarklyContext
      }) => (
        <ErrorBoundary
          airbrakeProjectId={appConfig?.airbrakeProjectId ?? ""}
          airbrakeProjectKey={appConfig?.airbrakeProjectKey ?? ""}
          appEnv={appConfig?.environment}
        >
          <ThemeProvider
            theme={{
              name: "default",
              values: theme.data,
              metadata: clientMetadata,
              subClientMetadata: subClientMetadata || clientMetadata
            }}
          >
            <ThemedWorkflowGlobalStyles />
            <ThemedGlobalStyles />
            <RouterSuccess
              onLogout={onLogout}
              onAdminLogout={onAdminLogout}
              onExpireSession={onExpireSession}
              authToken={authToken}
              adminAuthToken={adminAuthToken}
              locationHistory={locationHistory}
              clientSettings={clientSettings}
              clientMetadata={clientMetadata}
              subClientMetadata={subClientMetadata}
              workflows={workflows}
              workflowMetadata={workflowMetadata}
              localStorageItems={localStorageItems}
              profileId={profileId}
              paymentComplete={paymentComplete}
              oidc={oidc}
              setLaunchDarklyFlags={setLaunchDarklyFlags}
              setLaunchDarklyContext={setLaunchDarklyContext}
            />
          </ThemeProvider>
        </ErrorBoundary>
      )
    )
  ]
]);

const ReduxApp = connect(
  state => ({
    authToken: getAccessToken(state),
    adminAuthToken: getAdminAccessToken(state),
    appConfig: R.path(["config"], state),
    locationHistory: R.path(["global", "locationHistory"], state),
    theme: R.path(["global", "theme"], state),
    clientMetadata: R.path(["global", "clientMetadata"], state),
    subClientMetadata: R.path(["global", "subClientMetadata"], state),
    settings: R.path(["global", "settings"], state),
    workflows: R.path(["global", "workflows"], state),
    workflowMetadata: R.path(["workflow", "workflowMetadata"], state),
    defaultClientSlug: R.path(["config", "defaultClientSlug"], state),
    defaultClientApp: R.path(["config", "defaultClientApp"], state),
    profileId: R.path(["profile", "customerManagement", "profileId"], state),
    paymentComplete: R.path(["checkout", "payment", "complete"], state),
    localStorageItems: R.path(["global", "localStorage"], state), // prevent conflicts with global localStorage variable
    oidc: getOIDC(state)
  }),
  dispatch => ({
    onLogout: () => dispatch(logout()),
    onAdminLogout: () => dispatch(adminLogout()),
    onExpireSession: () => dispatch(initiateExpiredSession()),
    setLaunchDarklyFlags: payload => dispatch(setLaunchDarklyFlags(payload)),
    setLaunchDarklyContext: payload => dispatch(setLaunchDarklyContext(payload))
  })
)(
  ({
    authToken,
    adminAuthToken,
    appConfig,
    onLogout,
    onAdminLogout,
    onExpireSession,
    locationHistory,
    theme,
    clientMetadata,
    subClientMetadata,
    settings,
    workflows,
    workflowMetadata,
    defaultClientSlug,
    defaultClientApp,
    profileId,
    paymentComplete,
    localStorageItems,
    oidc,
    setLaunchDarklyFlags,
    setLaunchDarklyContext
  }) => {
    const AppPhase = AppPhaseSwitch([
      theme,
      clientMetadata,
      settings,
      workflows
    ]);

    return (
      <AppPhase
        onLogout={onLogout}
        onAdminLogout={onAdminLogout}
        onExpireSession={onExpireSession}
        authToken={authToken}
        adminAuthToken={adminAuthToken}
        locationHistory={locationHistory}
        theme={theme}
        clientMetadata={clientMetadata}
        subClientMetadata={subClientMetadata}
        workflows={workflows}
        workflowMetadata={workflowMetadata}
        defaultClientSlug={defaultClientSlug}
        defaultClientApp={defaultClientApp}
        clientSettings={settings}
        localStorageItems={localStorageItems}
        profileId={profileId}
        paymentComplete={paymentComplete}
        appConfig={appConfig}
        oidc={oidc}
        setLaunchDarklyFlags={setLaunchDarklyFlags}
        setLaunchDarklyContext={setLaunchDarklyContext}
      />
    );
  }
);

export const ConfigContext = React.createContext({});

const App = ({ config }) => {
  const NavStore = createNavStore(config);

  return (
    <ConfigContext.Provider value={config}>
      <ReduxProvider store={NavStore}>
        <ReduxApp />
      </ReduxProvider>
    </ConfigContext.Provider>
  );
};

export default App;

import { MATISSE_BLUE, STORM_GREY, WHITE } from "../../../../constants/colors";
import { constants } from "@thecb/components";

const headerBackgroundColor = STORM_GREY;
const headerTextColor = WHITE;
const linkColor = MATISSE_BLUE;
const miniCartColor = MATISSE_BLUE;

export const fallbackValues = {
  headerBackgroundColor,
  headerTextColor,
  linkColor,
  miniCartColor
};

export const DIVIDER_STYLES = {
  mobile: {
    height: "0.25rem",
    width: "100vw"
  },
  desktop: {
    height: "0.125rem",
    width: "100%"
  }
};

export const FONT_SIZE = constants.styleConstants.FONT_SIZE;

export const SPACING = {
  XS: "0.5rem",
  SM: "0.875rem",
  MD: "1rem",
  LG: "1.25rem",
  XL: "1.5rem",
  XXL: "2rem"
};

export const CART_LAYOUT_PADDING = {
  mobile: {
    cartsContainer: SPACING.XL,
    cart: `${SPACING.XXL} 0 ${SPACING.XXL}`,
    cartItem: `${SPACING.MD} 0`
  },
  desktop: {
    cartsContainer: `${SPACING.MD} ${SPACING.XXL} ${SPACING.XXL}`,
    cart: `2.5rem 0 ${SPACING.XXL}`,
    cartItem: `1.125rem 0`
  }
};

export const CART_MAX_WIDTH = "760px";

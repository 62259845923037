import React, { useContext } from "react";
import { themeComponent } from "../../../util/themeUtils";
import { fallbackValues } from "./RegistrationBanner.theme";
import * as Styled from "./RegistrationBanner.styled";
import ArrowRightIcon from "../../atoms/icons/ArrowRightIcon";
import { Box, Cluster } from "../../atoms/layouts";
import Text from "../../atoms/text";
import { ThemeContext } from "styled-components";
import {
  FONT_SIZE,
  FONT_WEIGHT_SEMIBOLD
} from "../../../constants/style_constants";
import withWindowSize from "../../withWindowSize";
import Heading from "../../atoms/heading";

const RegistrationBanner = ({
  clientName,
  extraStyles,
  loginLink = "/login",
  registrationLink = "/registration",
  themeValues,
  titleAs,
  titleVariant
}) => {
  const themeContext = useContext(ThemeContext);
  const { isMobile } = themeContext;
  return (
    <Styled.BannerContainer
      extraStyles={extraStyles}
      themeValues={themeValues}
      isMobile={isMobile}
      aria-label="Registration Banner"
      justify="center"
    >
      <Styled.ContentContainer
        align="center"
        justify="space-between"
        overflow="visible"
        isMobile={isMobile}
        nowrap
      >
        <Box padding="0" textAlign="left">
          <Heading
            as={titleAs}
            color={themeValues.primaryColor}
            fontSize={isMobile ? FONT_SIZE.XL : "1.5rem"}
            variant={titleVariant}
            weight={FONT_WEIGHT_SEMIBOLD}
          >
            Register for a {clientName} Wallet
          </Heading>
          <Text
            extraStyles={`
              display: block;
              padding: ${isMobile ? ".125rem 0 1rem" : "0"}
            `}
            fontSize={isMobile ? FONT_SIZE.MD : FONT_SIZE.LG}
            color={themeValues.secondaryColor}
          >
            Save payment methods and billing information for fast, easy, and
            safe payments with {clientName}
          </Text>
        </Box>
        <Styled.ButtonContainer
          justify="space-between"
          fullHeight
          childGap="0.5rem"
          isMobile={isMobile}
        >
          <Styled.RegisterLink
            variant="secondary"
            color={themeValues.primaryColor}
            contentOverride
            fontWeight={FONT_WEIGHT_SEMIBOLD}
            url={registrationLink}
          >
            <Cluster
              justify="center"
              align="center"
              extraStyles="min-width: 100%"
            >
              <Text
                extraStyles="margin-right: 0.5rem"
                fontSize={isMobile ? FONT_SIZE.MD : FONT_SIZE.LG}
                color={themeValues.primaryColor}
                weight={FONT_WEIGHT_SEMIBOLD}
              >
                Register Now
              </Text>
              <ArrowRightIcon />
            </Cluster>
          </Styled.RegisterLink>
          <Styled.LoginLink
            text="Log In"
            variant="secondary"
            contentOverride
            url={loginLink}
          >
            <Text
              fontSize={isMobile ? FONT_SIZE.SM : FONT_SIZE.MD}
              color={themeValues.primaryColor}
              weight={FONT_WEIGHT_SEMIBOLD}
            >
              or Log In
            </Text>
          </Styled.LoginLink>
        </Styled.ButtonContainer>
      </Styled.ContentContainer>
    </Styled.BannerContainer>
  );
};

export default withWindowSize(
  themeComponent(RegistrationBanner, "RegistrationBanner", fallbackValues)
);

import React from "react";
import styled from "styled-components";
import { Box, Motion } from "../../atoms/layouts";
import { fallbackValues } from "./NavMenu.theme.js";
import { themeComponent } from "../../../util/themeUtils";

const menuVariants = {
  invisible: {
    x: "-100vw",
    transition: {
      ease: "easeOut",
      duration: 0.5
    }
  },
  visible: {
    x: "0",
    transition: {
      ease: "easeIn",
      duration: 0.5
    }
  }
};

const ImposterMenu = styled(Motion)`
  /* Overlay starts below the header */
  position: fixed;
  top: ${({ headerSize }) => headerSize};
  left: 0;
  right: 0;
  bottom: 0;
  /* Dim background for everything below the navbar */
  background: ${({ themeValues }) =>
    themeValues?.overlayColor || "rgba(0, 0, 0, 0.5)"};
`;

const NavMenuMobile = ({
  id,
  menuContent = [],
  visible = false,
  headerSize = "72px",
  themeValues
}) => {
  return (
    <ImposterMenu
      id={id}
      variants={menuVariants}
      initial="invisible"
      animate={visible ? "visible" : "invisible"}
      headerSize={headerSize}
      themeValues={themeValues}
    >
      {/*
        The Box is the “menu” portion.
        It’s narrower, but the parent ImposterMenu is full-screen & gray.
      */}
      <Box
        width="100%"
        maxWidth="400px"
        padding="1rem 0.5rem"
        extraStyles={`position: relative; max-width: 400px; height: calc(100vh - 72px);`}
        background={themeValues.backgroundColor}
      >
        {menuContent}
      </Box>
    </ImposterMenu>
  );
};

export default themeComponent(
  NavMenuMobile,
  "NavMenu",
  fallbackValues,
  "profile"
);

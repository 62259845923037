import React from "react";
import { MATISSE_BLUE } from "../../../constants/colors";

const ExternalLinkIcon = ({
  linkColor = MATISSE_BLUE,
  text,
  ariaLabel = "External Link",
  ...rest
}) => (
  <svg
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    aria-label={ariaLabel}
    {...rest}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M1.86092 1.59483C1.92074 1.56953 1.98651 1.55554 2.05554 1.55554H2.6111H4.94443C5.22057 1.55554 5.44443 1.7794 5.44443 2.05554V2.6111C5.44443 2.88724 5.22057 3.1111 4.94443 3.1111H3.1111V10.8889H10.8889V9.05554C10.8889 8.7794 11.1127 8.55554 11.3889 8.55554H11.9444C12.2206 8.55554 12.4444 8.7794 12.4444 9.05554V11.9444C12.4444 12.2206 12.2206 12.4444 11.9444 12.4444H11.3889C11.3507 12.4444 11.3135 12.4401 11.2778 12.432C11.242 12.4401 11.2048 12.4444 11.1667 12.4444H2.6111H2.05554C1.7794 12.4444 1.55554 12.2206 1.55554 11.9444V11.3889V2.6111V2.05554C1.55554 2.05554 1.55554 2.05554 1.55554 2.05554C1.55554 2.01307 1.56084 1.97183 1.57081 1.93246C1.60944 1.77983 1.71826 1.65517 1.86092 1.59483ZM7.77776 2.05554C7.77776 1.7794 8.00162 1.55554 8.27776 1.55554H11.3889H11.9444C12.2206 1.55554 12.4444 1.7794 12.4444 2.05554V2.6111V5.72221C12.4444 5.99835 12.2206 6.22221 11.9444 6.22221H11.3889C11.1127 6.22221 10.8889 5.99835 10.8889 5.72221V4.26638L6.89793 8.25733C6.70267 8.45259 6.38608 8.45259 6.19082 8.25733L5.79798 7.8645C5.60272 7.66923 5.60272 7.35265 5.79798 7.15739L9.84428 3.1111H8.27776C8.00162 3.1111 7.77776 2.88724 7.77776 2.6111V2.05554Z"
      fill={linkColor}
    />
    <mask
      id={`mask0_902_435-${text}`}
      style={{ maskType: "alpha" }}
      maskUnits="userSpaceOnUse"
      x="1"
      y="1"
      width="12"
      height="12"
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M1.86092 1.59483C1.92074 1.56953 1.98651 1.55554 2.05554 1.55554H2.6111H4.94443C5.22057 1.55554 5.44443 1.7794 5.44443 2.05554V2.6111C5.44443 2.88724 5.22057 3.1111 4.94443 3.1111H3.1111V10.8889H10.8889V9.05554C10.8889 8.7794 11.1127 8.55554 11.3889 8.55554H11.9444C12.2206 8.55554 12.4444 8.7794 12.4444 9.05554V11.9444C12.4444 12.2206 12.2206 12.4444 11.9444 12.4444H11.3889C11.3507 12.4444 11.3135 12.4401 11.2778 12.432C11.242 12.4401 11.2048 12.4444 11.1667 12.4444H2.6111H2.05554C1.7794 12.4444 1.55554 12.2206 1.55554 11.9444V11.3889V2.6111V2.05554C1.55554 2.05554 1.55554 2.05554 1.55554 2.05554C1.55554 2.01307 1.56084 1.97183 1.57081 1.93246C1.60944 1.77983 1.71826 1.65517 1.86092 1.59483ZM7.77776 2.05554C7.77776 1.7794 8.00162 1.55554 8.27776 1.55554H11.3889H11.9444C12.2206 1.55554 12.4444 1.7794 12.4444 2.05554V2.6111V5.72221C12.4444 5.99835 12.2206 6.22221 11.9444 6.22221H11.3889C11.1127 6.22221 10.8889 5.99835 10.8889 5.72221V4.26638L6.89793 8.25733C6.70267 8.45259 6.38608 8.45259 6.19082 8.25733L5.79798 7.8645C5.60272 7.66923 5.60272 7.35265 5.79798 7.15739L9.84428 3.1111H8.27776C8.00162 3.1111 7.77776 2.88724 7.77776 2.6111V2.05554Z"
        fill={linkColor}
      />
    </mask>
    <g mask={`url(#mask0_902_435-${text})`}>
      <rect x="-0.0864258" width="14" height="14" fill="none" />
    </g>
  </svg>
);

export default ExternalLinkIcon;

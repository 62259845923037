import React, { createRef, Fragment, useRef, useState } from "react";
import { themeComponent } from "../../../util/themeUtils";
import { fallbackValues } from "./RadioSection.theme";
import SolidDivider from "../../atoms/solid-divider/SolidDivider";
import { Box, Stack } from "../../atoms/layouts";
import { createIdFromString, wrapIndex } from "../../../util/general";
import {
  ARROW_DOWN,
  ARROW_LEFT,
  ARROW_RIGHT,
  ARROW_UP,
  ENTER,
  SPACEBAR
} from "../../../constants/keyboard";
import InnerRadioSection from "./InnerRadioSection";
import { MANATEE_GREY } from "../../../constants/colors";

/**
- The RadioSection component takes either a flat array (via the 'sections' 
  prop) of section objects or a multidimensional array (via the 'groupedSections' prop) of section objects. Note that if using a multidimensional array, the nesting cannot exceed 2 levels deep.
- Each 'section' object should look like:
  {
    title: <React Component(s)>,
    id: <String> "identifier of section",
    disabled: boolean, (displays section and grayed out radio but disables interaction)
    hideRadioButton: boolean, (keeps section displayed but hides radio and disables open/close function),
    hidden: boolean, (hides section entirely)
    dataQa: string,
    content: <React Component(s)> e.g.: <Box><Stack>cool content stuff</Stack></Box> (any collection of components will work),
    rightTitleContent: <React Component(s)> (rendered on the very right of the title section, use to supplement "rightIcons" with text, as in expired CC status, or render other custom content)
  }
- It also takes an "openSection" which should equal the id of the section that 
  should be open, along with "toggleOpenSection" 
- RadioSection will call "toggleOpenSection" with the id of the section
  that it is in. 
- It is up to the engineer to store the open section value in state up from the 
  component using a useState() hook or a reducer.
- The section itself comes with some motion to open/close. To add more motion 
  to the content, wrap your content with a Motion layout primitive and provide appropriate props.
*/

const idString = section =>
  typeof section.title === "string"
    ? createIdFromString(section.title)
    : section.id;

const RadioSection = ({
  themeValues,
  isMobile,
  supportsTouch,
  sections = [],
  openSection = "",
  toggleOpenSection,
  staggeredAnimation = false,
  initiallyOpen = true,
  openHeight = "auto",
  containerStyles = "",
  ariaDescribedBy,
  isSectionRequired = false,
  groupedSections = [],
  borderOverride,
  ...rest
}) => {
  const [focused, setFocused] = useState(null);

  const sectionRefs = useRef(
    [...Array(sections.length)].map(() => createRef())
  );

  // Flatten the groupedSections while keeping track of indices
  const flattenedGroupedSections = groupedSections.flatMap(
    (group, groupIndex) =>
      group.map((section, sectionIndex) => ({
        ...section,
        groupIndex,
        sectionIndex
      }))
  );
  const groupedSectionsRefs = useRef(
    [...Array(flattenedGroupedSections.length)].map(() => createRef())
  );

  const getFlatGroupIndex = (groupIndex, sectionIndex) =>
    flattenedGroupedSections.findIndex(
      item =>
        item.groupIndex === groupIndex && item.sectionIndex === sectionIndex
    );

  const handleKeyDown = (e, sectionID, sectionIndex, isGroup) => {
    const { currentTarget, target, keyCode } = e;
    if (currentTarget !== target) {
      return;
    }
    const refs = isGroup ? groupedSectionsRefs : sectionRefs;
    const currSection = isGroup ? flattenedGroupedSections : sections;

    // Allow Enter and Space to select a section
    if (keyCode === ENTER || keyCode === SPACEBAR) {
      e.preventDefault();
      toggleOpenSection(sectionID);
    }

    // Allow Up and Down arrow navigation between sections
    if (
      keyCode == ARROW_UP ||
      keyCode == ARROW_DOWN ||
      keyCode == ARROW_LEFT ||
      keyCode == ARROW_RIGHT
    ) {
      e.preventDefault();
      const indexIncrement =
        keyCode == ARROW_RIGHT || keyCode == ARROW_DOWN ? 1 : -1;
      const nextIndex = wrapIndex(
        sectionIndex + indexIncrement,
        currSection.length
      );
      const nextRef = refs?.current[nextIndex]?.current;
      if (nextRef) {
        nextRef.focus();
        const nextRadioID = currSection[nextIndex]?.id;
        setFocused(nextRadioID);
      }
    }
  };

  return (
    <Box
      padding="1px"
      border={borderOverride || `1px solid ${themeValues.borderColor}`}
      borderRadius="4px"
      extraStyles={containerStyles}
      role="radiogroup"
      aria-required={isSectionRequired}
      {...rest}
    >
      <Stack childGap="0">
        {!!sections &&
          sections
            .filter(section => !section.hidden)
            .map((section, i) => (
              <Fragment key={`radio-section-${i}`}>
                <InnerRadioSection
                  themeValues={themeValues}
                  sectionIndex={i}
                  section={section}
                  sectionRefs={sectionRefs}
                  focused={focused}
                  setFocused={setFocused}
                  openHeight={openHeight}
                  openSection={openSection}
                  toggleOpenSection={toggleOpenSection}
                  onKeyDown={e =>
                    !section.disabled && handleKeyDown(e, section.id, i)
                  }
                  ariaLabelledBy={section.id}
                  ariaDescribedBy={`right-icons-${idString(section)}`}
                  isLastGroupedItemInSection={false}
                />
              </Fragment>
            ))}
        {!!groupedSections &&
          groupedSections.map((sectionGroup, sectionGroupIndex) =>
            sectionGroup
              .filter(unfilteredSection => !unfilteredSection.hidden)
              .map((section, sectionIndex) => {
                const flatGroupSectionIndex = getFlatGroupIndex(
                  sectionGroupIndex,
                  sectionIndex
                );
                return (
                  <Fragment key={`key-${sectionGroupIndex}-${sectionIndex}`}>
                    <InnerRadioSection
                      themeValues={themeValues}
                      sectionIndex={flatGroupSectionIndex}
                      section={section}
                      sectionRefs={groupedSectionsRefs}
                      focused={focused}
                      setFocused={setFocused}
                      openHeight={openHeight}
                      ariaLabelledBy={section.id}
                      ariaDescribedBy={`right-icons-${idString(section)}`}
                      openSection={openSection}
                      toggleOpenSection={toggleOpenSection}
                      onKeyDown={e =>
                        !section.disabled &&
                        handleKeyDown(
                          e,
                          section.id,
                          flatGroupSectionIndex,
                          true
                        )
                      }
                      isLastGroupedItemInSection={
                        sectionIndex === sectionGroup.length - 1
                      }
                    />
                    {sectionIndex === sectionGroup.length - 1 &&
                      sectionGroupIndex !== groupedSections.length - 1 && (
                        <SolidDivider
                          borderSize="1px"
                          borderColor={MANATEE_GREY}
                        />
                      )}
                  </Fragment>
                );
              })
          )}
      </Stack>
    </Box>
  );
};

export default themeComponent(RadioSection, "RadioSection", fallbackValues);

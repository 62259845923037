import React, {
  memo,
  useContext,
  useState,
  Fragment,
  useEffect,
  useRef
} from "react";
import { pipe } from "ramda";
import { ThemeContext } from "styled-components";
import {
  Box,
  Cover,
  Stack,
  Motion,
  ButtonWithAction,
  Paragraph,
  Text,
  Title,
  NavHeader,
  HamburgerButton,
  CarrotIcon,
  SettingsIconSmall,
  AccountsIconSmall,
  PropertiesIconSmall,
  WalletIconSmall,
  FindIconSmall,
  HistoryIconSmall,
  ExternalLink,
  InternalLink,
  NavMenuDesktop,
  NavMenuMobile,
  withWindowSize,
  util,
  constants,
  Cluster
} from "@thecb/components";
import { test, compose, join, juxt, toUpper, head, tail, concat } from "ramda";
import { push } from "connected-react-router";
import { useLocation } from "react-router-dom";
import { bindActionCreators } from "redux";
import { connect } from "react-redux";

import { logout } from "~/state/reducer";
import { fallbackValues } from "./Header.theme";
import { themeComponent } from "../../util/themeUtils";
import {
  configureRoutes,
  isInCustomerManagement,
  getProfileChildRoutes,
  filterRoutesByCustomerManagement,
  filterRoutesByFindableAccounts
} from "../../util/router-utils";
import { URL_TEST } from "../../constants/regex_constants";
import MiniCart from "../../apps/checkout/pages/multi-cart/MiniCart";

const ProfileMenu = ({
  isLoggedIn,
  onLogout,
  userFirstName = "",
  userLastName = "",
  userEmail = "",
  push,
  themeValues,
  routing,
  navMenuOpen,
  setNavMenuOpen,
  profileId,
  walletEnabled,
  onWalletPage,
  findableAccounts
}) => {
  const enabledRoutes = pipe(
    getProfileChildRoutes,
    filterRoutesByCustomerManagement(isInCustomerManagement),
    configureRoutes,
    filterRoutesByFindableAccounts(findableAccounts)
  )(routing);

  let menuTimeoutID;
  const { isMobile, supportsTouch } = useContext(ThemeContext);
  const icons = {
    accounts: <AccountsIconSmall variant={themeValues.iconVariant} />,
    properties: <PropertiesIconSmall variant={themeValues.iconVariant} />,
    settings: <SettingsIconSmall variant={themeValues.iconVariant} />,
    wallet: <WalletIconSmall variant={themeValues.iconVariant} iconIndex={1} />,
    find: <FindIconSmall variant={themeValues.iconVariant} iconIndex={1} />,
    history: <HistoryIconSmall variant={themeValues.iconVariant} />
  };
  const capitalize = compose(join(""), juxt([compose(toUpper, head), tail]));
  const userInitials = toUpper(concat(head(userFirstName), head(userLastName)));
  const icon = {
    open: {
      rotate: "-180deg",
      transition: {
        duration: 0.3
      }
    },
    closed: {
      rotate: "0deg",
      transition: {
        duration: 0.3
      }
    }
  };
  const profileMenuButton = (
    <Box
      onMouseEnter={() => setNavMenuOpen(true)}
      onMouseLeave={() => {
        menuTimeoutID = setTimeout(() => setNavMenuOpen(false), 500);
      }}
      onKeyPress={e => e.key === "Enter" && setNavMenuOpen(!navMenuOpen)}
      onClick={
        isMobile && supportsTouch
          ? util.general.noop
          : () => setNavMenuOpen(!navMenuOpen)
      }
      onTouchEnd={
        isMobile && supportsTouch
          ? () => setNavMenuOpen(!navMenuOpen)
          : util.general.noop
      }
      aria-controls="navigation"
      aria-label="Menu"
      type="button"
      extraStyles={`cursor: pointer;
      padding: 16px 10px;
      &:focus {
        outline: 3px solid ${themeValues.accessibilityColor};
        outline-offset: 2px;
      }`}
      tabIndex="0"
    >
      <Cluster justify="center" align="center">
        <Box
          padding="0"
          background={themeValues.userTextBackgroundColor}
          borderRadius="50%"
          boxShadow={
            navMenuOpen &&
            `
              0px 1px 4px 2px rgba(0, 0, 0, 0.1),
              0px 2px 1px 0px rgba(0, 0, 0, 0.15);`
          }
          extraStyles={`width: 36px; height: 36px; display: flex; justify-content: center; align-items: center; margin: 0.5rem;`}
        >
          <Text
            variant="pS"
            color={themeValues.userTextColor}
            weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
            extraStyles={`display: inline-block; line-height: 0;`}
          >
            {userInitials}
          </Text>
        </Box>
        <Box padding="0">
          <Motion
            variants={icon}
            animate={navMenuOpen ? "open" : "closed"}
            positionTransition
            extraStyles={`width: 24px; height: 24px; display: flex; justify-content: center; align-items: center;`}
          >
            <CarrotIcon />
          </Motion>
        </Box>
      </Cluster>
    </Box>
  );

  const guestCheckout = (
    <Box padding="0" extraStyles={`margin-left: 15px`}>
      <Text
        variant="pS"
        color={themeValues.textColor}
        weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
      >
        Guest Checkout
      </Text>
    </Box>
  );

  const profileMenuHeader =
    userEmail !== "" ? (
      <Box
        padding={isMobile ? "0.5rem 0 1rem 0.5rem" : "0 0 1rem 0.5rem"}
        borderSize="1px"
        borderColor={themeValues.menuAccentColor}
        borderWidthOverride="0px 0px 1px 0px"
        key="user-info"
        extraStyles={isMobile ? `margin: 0 1rem;` : ``}
      >
        <Stack childGap="0">
          <Title
            as="h6"
            weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
            color={themeValues.textColor}
            key="username"
            variant="small"
          >
            {`${userFirstName} ${userLastName}`}
          </Title>
          <Paragraph
            variant="pS"
            color={`${themeValues.textColor}BF`}
            extraStyles={`font-style: italic;`}
            key="useremail"
          >
            {userEmail}
          </Paragraph>
        </Stack>
      </Box>
    ) : (
      <Fragment key="user-info" />
    );

  const renderRoute = route => (
    <ButtonWithAction
      key={route}
      variant="whitePrimary"
      contentOverride
      action={() => {
        setNavMenuOpen(false);
        push(
          isInCustomerManagement
            ? `/admin/${profileId}/profile/${route}`
            : `/profile/${route}`
        );
      }}
      extraStyles={`&:focus {
        outline: 3px solid ${themeValues.accessibilityColor};
        outline-offset: 2px;
      } ${isMobile ? `margin: 0 1rem;` : `border-radius: 4px;`}`}
    >
      <Box padding="0" minWidth="100%" extraStyles={`text-align: left;`}>
        {icons[route]}
        <Text
          variant="pL"
          color={themeValues.textColor}
          weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
          extraStyles={`margin-left: 1rem`}
        >
          {capitalize(route)}
        </Text>
      </Box>
    </ButtonWithAction>
  );

  const profileMenuLogout = !isInCustomerManagement && (
    <Box
      padding="1rem 0.5rem 0 0.5rem"
      key="logout"
      minWidth="100%"
      onBlur={() => setTimeout(() => setNavMenuOpen(false), 200)}
    >
      <ButtonWithAction
        variant="whitePrimary"
        text="Log Out"
        action={() => {
          setNavMenuOpen(false);
          onLogout();
        }}
        extraStyles={`
          margin: 0;
          width: 100%;
          padding: 0.75rem 2rem;
          border: 2px solid ${themeValues.textColor};
          &:active {
            border: 2px solid ${themeValues.textColor};
          }
          &:focus {
            outline: 3px solid ${themeValues.accessibilityColor};
            outline-offset: 2px;
          }
        `}
      />
    </Box>
  );

  const profileMenuItems = [
    profileMenuHeader,
    ...enabledRoutes.map(renderRoute),
    profileMenuLogout
  ];

  const profileMenuContent = (
    <Stack
      childGap="0"
      fullHeight={isMobile}
      bottomItem={isMobile ? profileMenuItems.length : ``}
      role="navigation"
    >
      {profileMenuItems}
    </Stack>
  );
  return isMobile ? (
    <Box padding="0" extraStyles={"margin-top: auto; margin-bottom: auto;"}>
      {isLoggedIn ? (
        <HamburgerButton
          isActive={navMenuOpen}
          onClick={() => setNavMenuOpen(!navMenuOpen)}
          activeColor={themeValues.textColor}
          inactiveColor={themeValues.textColor}
          controls="navmenu"
        />
      ) : (
        <Fragment />
      )}
      <NavMenuMobile
        id="navmenu"
        menuContent={profileMenuContent}
        visible={navMenuOpen}
      />
    </Box>
  ) : (
    <Box
      padding="0"
      extraStyles={`position: relative; margin-top: auto; margin-bottom: auto`}
    >
      {isLoggedIn ? (
        profileMenuButton
      ) : walletEnabled && !onWalletPage ? (
        guestCheckout
      ) : (
        <Fragment />
      )}
      <NavMenuDesktop
        id="navmenu"
        menuContent={profileMenuContent}
        visible={navMenuOpen}
        onMouseLeave={() => setTimeout(() => setNavMenuOpen(false), 200)}
        onMouseEnter={() => clearTimeout(menuTimeoutID)}
        onFocus={() => clearTimeout(menuTimeoutID)}
      />
    </Box>
  );
};

const Header = ({
  metadata,
  themeValues,
  isLoggedIn,
  onLogout,
  userFirstName = "",
  userLastName = "",
  userEmail = "",
  profileId,
  push,
  routing,
  findableAccounts,
  walletEnabled
}) => {
  const { isMobile } = useContext(ThemeContext);
  const [navMenuOpen, setNavMenuOpen] = useState(false);
  const { pathname } = useLocation();
  const onWalletPage = pathname.includes("/wallet");
  useEffect(() => {
    const elem = document.querySelector("#root");
    if (navMenuOpen && isMobile) {
      elem.style.overflow = "hidden";
    } else {
      elem.style.overflow = "visible";
    }
    return () => (elem.style.overflow = "visible");
  }, [navMenuOpen]);
  const headerFocusRef = useRef(null);
  useEffect(() => {
    if (headerFocusRef.current) {
      headerFocusRef.current.focus();
    }
  }, [pathname]);
  const headerLogoSrc = metadata?.data?.headerLogo ?? "";
  const headerLogoLink = metadata?.data?.headerLogoLink;
  const defaultLogoAlt = metadata?.data?.clientDisplayName
    ? `${metadata.data.clientDisplayName} - payments`
    : "Payments";
  const logoAlt = metadata?.data?.headerLogoAlt ?? defaultLogoAlt;

  const HeaderLogoWrapper = ({ children }) =>
    headerLogoLink ? (
      test(URL_TEST, headerLogoLink) ? (
        <ExternalLink
          extraStyles={`&:focus {
          outline: 3px solid ${themeValues.accessibilityColor};
          outline-offset: 2px;
        }`}
          href={headerLogoLink}
        >
          {children}
        </ExternalLink>
      ) : (
        <InternalLink
          extraStyles={`&:focus {
          outline: 3px solid ${themeValues.accessibilityColor};
          outline-offset: 2px;
        }`}
          to={headerLogoLink}
        >
          {children}
        </InternalLink>
      )
    ) : (
      <Fragment>{children}</Fragment>
    );
  const headerLogo = (
    <div ref={headerFocusRef} style={{ outline: "none" }}>
      <HeaderLogoWrapper>
        <Box padding="0" minHeight="100%">
          <Cover singleChild minHeight="100%">
            <Box padding="0">
              <img
                src={headerLogoSrc}
                height={isMobile ? "42px" : themeValues.logoHeight}
                alt={logoAlt}
              />
            </Box>
          </Cover>
        </Box>
      </HeaderLogoWrapper>
    </div>
  );
  const rightContent = (
    <Box padding="0" extraStyles={"display: flex; flex-flow: row;"}>
      <MiniCart
        onCartPage={pathname.includes("/cart")}
        isLoggedIn={isLoggedIn}
      />
      <ProfileMenu
        themeValues={themeValues}
        isLoggedIn={isLoggedIn}
        onLogout={onLogout}
        userFirstName={userFirstName}
        userLastName={userLastName}
        userEmail={userEmail}
        profileId={profileId}
        push={push}
        routing={routing}
        findableAccounts={findableAccounts}
        navMenuOpen={navMenuOpen}
        setNavMenuOpen={setNavMenuOpen}
        walletEnabled={walletEnabled}
        onWalletPage={onWalletPage}
      />
    </Box>
  );

  return (
    <Box padding="0" role="banner" extraStyles={`z-index: 1000;`}>
      <NavHeader
        backgroundColor={themeValues.backgroundColor}
        leftContent={headerLogo}
        rightContent={rightContent}
        isMobile={isMobile}
      />
    </Box>
  );
};

const getUserDetails = state => {
  const { localStorage: localStorageItems = {} } = state?.global; // prevent conflicts with actual global localStorage variable
  const userFirstName = localStorageItems.firstName || "";
  const userLastName = localStorageItems.lastName || "";
  const userEmail = localStorageItems.email || "";

  return {
    userFirstName,
    userLastName,
    userEmail
  };
};

const mapStateToProps = state => {
  return {
    ...getUserDetails(state),
    routing: state?.global?.settings?.data?.routes ?? [],
    findableAccounts:
      state?.profile?.settings?.resources?.value?.filteredFindableAccounts ?? []
  };
};

const mapDispatchToProps = (dispatch, { onLogout = logout }) =>
  bindActionCreators(
    {
      onLogout,
      push
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withWindowSize(memo(themeComponent(Header, "Header", fallbackValues))));

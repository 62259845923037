import React from "react";
import styled from "styled-components";
import { fallbackValues } from "./ToggleSwitch.theme";
import { themeComponent } from "../../../util/themeUtils";
import { Box, Center, Cover, Cluster, Motion } from "../layouts";
import { FONT_WEIGHT_SEMIBOLD } from "../../../constants/style_constants";
import { CHARADE_GREY } from "../../../constants/colors";
import { ENTER } from "../../../constants/keyboard";
import { noop } from "../../../util/general";

const HiddenToggleSwitchBox = styled.input`
  opacity: 0;
  margin: 0;
  position: absolute;
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  height: ${({ isMobile }) => (isMobile ? `22px` : `24px`)};
  width: ${({ isMobile }) => (isMobile ? `40px` : `44px`)};
`;

const VisibleSwitchComponent = styled(Motion)`
  width: ${({ isMobile }) => (isMobile ? `40px` : `44px`)};
  height: ${({ isMobile }) => (isMobile ? `22px` : `24px`)};
  border-radius: 12px;
  border: none;
  position: relative;
  box-sizing: border-box;
  cursor: ${({ disabled }) => (disabled ? "auto" : "pointer")};
  display: inline-block;

  &:hover {
    box-shadow: ${({ disabled }) =>
      disabled ? "none" : "0px 2px 5px 0px rgba(0, 0, 0, 0.5) !important"};
  }

  &:focus {
    box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.5);
  }
`;

const ToggleSwitchRingComponent = styled(Motion)`
  position: absolute;
  width: ${({ isMobile }) => (isMobile ? `14px` : `16px`)};
  height: ${({ isMobile }) => (isMobile ? `14px` : `16px`)};
  border: none;
  border-radius: 50%;
  box-sizing: border-box;
`;

const ToggleSwitch = ({
  ariaDescribedBy = "",
  isOn = false,
  onToggle = noop,
  disabled = false,
  name = "toggle",
  label = null,
  labelLeft = false,
  labelStyles = "",
  labelAs = "label",
  themeValues,
  isMobile,
  dataQa,
  minWidth = "80px",
  extraStyles = ""
}) => {
  const nameId = name.replace(/ /g, "-");
  const toggleSwitchRingVariants = {
    off: {
      backgroundColor: disabled
        ? themeValues.disabledBackground
        : themeValues.onBackground,
      left: "4px",
      top: "3px",
      bottom: "3px",
      right: "20px",
      transition: {
        ease: "backIn"
      }
    },
    on: {
      backgroundColor: themeValues.white,
      right: "8px",
      top: "3px",
      bottom: "3px",
      left: "22px",
      transition: {
        ease: "backIn"
      }
    }
  };

  const visibleSwitchVariants = {
    off: {
      border: "1px solid",
      backgroundColor: disabled
        ? themeValues.disabledBackgroundLight
        : themeValues.white,
      borderColor: disabled
        ? themeValues.disabledBackground
        : themeValues.onBackground,
      transition: {
        ease: "easeOut"
      }
    },
    on: {
      border: "1px solid",
      backgroundColor: disabled
        ? themeValues.disabledBackground
        : themeValues.onBackground,
      borderColor: disabled
        ? themeValues.disabledBackground
        : themeValues.onBackground,
      transition: {
        ease: "easeIn"
      }
    }
  };

  const handleKeyDown = e => {
    if (e.keyCode === ENTER) {
      onToggle();
    }
  };

  return (
    <Box
      padding="0.5rem"
      extraStyles={`
        margin: 0 0.5rem;
        ${
          labelLeft ? themeValues.leftLabelStyles : themeValues.rightLabelStyles
        }${extraStyles}`}
    >
      <Center>
        <Cluster
          justify="space-between"
          align="center"
          overflow="visible"
          childGap="0"
          minWidth={minWidth ?? (isMobile ? "75px" : "84px")}
        >
          <Cover minHeight="100%" singleChild>
            <Box
              minWidth="100%"
              padding="0"
              extraStyles={`display: flex; align-items: center;`}
              dataQa={dataQa}
            >
              <HiddenToggleSwitchBox
                type="checkbox"
                aria-checked={isOn}
                aria-label={name}
                aria-labelledby={label ? `${nameId}-label` : null}
                aria-describedby={ariaDescribedBy ? ariaDescribedBy : null}
                checked={isOn}
                onChange={disabled ? noop : onToggle}
                disabled={disabled}
                id={`#${nameId}`}
                isMobile={isMobile}
              />
              <VisibleSwitchComponent
                onClick={disabled ? noop : onToggle}
                onKeyDown={disabled ? noop : handleKeyDown}
                initial={isOn ? "on" : "off"}
                animate={isOn ? "on" : "off"}
                variants={visibleSwitchVariants}
                tabIndex={disabled ? -1 : 0}
                disabled={disabled}
                isMobile={isMobile}
                useSpan
                tabindex="0"
              >
                <ToggleSwitchRingComponent
                  variants={toggleSwitchRingVariants}
                  isMobile={isMobile}
                />
              </VisibleSwitchComponent>
            </Box>
          </Cover>
          {label && (
            <Box
              as={labelAs}
              aria-label={`${name}: ${label}`}
              extraStyles={`
                font-weight: ${FONT_WEIGHT_SEMIBOLD};
                color: ${CHARADE_GREY};
                margin: 0 0 0 0.25rem; 
                position: relative; 
                bottom: 1px; 
                display: inline-block; 
                ${labelStyles}`}
              id={`${nameId}-label`}
              htmlFor={labelAs === "label" ? nameId : null}
              padding="0"
            >
              {label}
            </Box>
          )}
        </Cluster>
      </Center>
    </Box>
  );
};

export default themeComponent(ToggleSwitch, "ToggleSwitch", fallbackValues);

import React, { useContext, forwardRef } from "react";
import { ThemeContext } from "styled-components";
import { fallbackValues } from "../MultiCart.theme";
import {
  Box,
  ButtonWithAction,
  Paragraph,
  Text,
  MultiCartIcon,
  constants,
  util
} from "@thecb/components";
import { RAZZMATAZZ_RED } from "../../../../../constants/colors";

export const MultiCartButton = forwardRef(
  (
    {
      isMobile = false,
      itemsCount = 0,
      onCartScreen = false,
      openCart,
      total,
      cartSliderOpen
    },
    ref
  ) => {
    const themeContext = useContext(ThemeContext);
    const themeValues = util.theme.createThemeValues(
      themeContext,
      fallbackValues,
      "MultiCart"
    );
    return (
      <ButtonWithAction
        variant="smallGhost"
        contentOverride
        aria-label={`Shopping Cart with ${itemsCount} items, total price ${util.general.displayCurrency(
          total
        )}`}
        disabled={onCartScreen}
        action={openCart}
        aria-haspopup="dialog"
        padding={isMobile ? "0.25rem" : "0.5rem"}
        extraStyles={`
        margin: 0 ${isMobile ? "0.25rem" : "0"} 0 0;
        min-width: 33px;
        min-height: auto;
        &:disabled {
          background-color: transparent;
        }
        &:hover > * > span {
          text-decoration: none;
          color: ${constants.colors.WHITE};
          background-color: ${RAZZMATAZZ_RED};
        }`}
        ref={ref}
        data-qa="mini-cart"
      >
        <Box
          as="span"
          padding="0"
          extraStyles={`
            display: inline-flex;
            justify-content: center;
            gap: 4px;
            height: 100%;
            width: 100%;
          `}
        >
          {!isMobile && (
            <Paragraph
              variant="p"
              weight={constants.fontWeights.FONT_WEIGHT_SEMIBOLD}
              color={themeValues.miniCartColor}
              data-qa="shopping-cart-subtotal"
            >
              {util.general.displayCurrency(total)}
            </Paragraph>
          )}
          <MultiCartIcon
            iconFill={themeValues.miniCartColor}
            iconWidth={isMobile ? "20" : "25"}
            iconHeight={isMobile ? "20" : "23"}
            role="img"
            aria-hidden="true"
          />
          {itemsCount > 0 && (
            <Text
              weight={constants.fontWeights.FONT_WEIGHT_BOLD}
              color={constants.colors.WHITE}
              fontSize={isMobile ? "9px" : "11px"}
              extraStyles={`
              border-radius: 50%;
              min-height: ${isMobile ? "13px" : "17px"};
              min-width: ${isMobile ? "13px" : "17px"};
              position: absolute;
              top: ${isMobile ? "-3px" : "-1px"};
              right: ${isMobile ? "-1px" : "-7px"};
              background-color: ${RAZZMATAZZ_RED};
            `}
              data-qa="shopping-cart-item-count"
            >
              {itemsCount >= 100 ? "+" : itemsCount}
            </Text>
          )}
        </Box>
      </ButtonWithAction>
    );
  }
);

export default MultiCartButton;

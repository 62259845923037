import styled from "styled-components";
import { motion } from "framer-motion";

export const MotionWrapper = styled(motion.div)`
  position: ${({ position }) => position};
  display: ${({ display }) => display};
  box-sizing: border-box;
  padding: ${({ padding }) => padding};
  border: ${({ borderShorthand }) => borderShorthand};
  border-color: ${({ borderColor }) => borderColor};
  border-size: ${({ borderSize }) => borderSize};
  border-style: ${({ borderStyle }) => borderStyle};
  border-width: ${({ borderWidth }) => borderWidth};
  border-radius: ${({ borderRadius }) => borderRadius};
  background-color: ${({ backgroundColor }) => backgroundColor};
  box-shadow: ${({ boxShadow }) => boxShadow};
  min-height: ${({ minHeight }) => minHeight};
  min-width: ${({ minWidth }) => minWidth};
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  text-align: ${({ textAlign }) => textAlign};
  margin: ${({ margin }) => margin};

  &:hover,
  &:focus {
    ${({ hoverStyles }) => hoverStyles};
  }

  &:active {
    ${({ activeStyles }) => activeStyles};
  }

  &:disabled {
    ${({ disabledStyles }) => disabledStyles};
  }

  ${({ extraStyles }) => extraStyles};
`;

export const MotionSpanWrapper = styled(motion.span)`
  position: ${({ position }) => position};
  display: ${({ display }) => display};
  box-sizing: border-box;
  padding: ${({ padding }) => padding};
  border: ${({ borderShorthand }) => borderShorthand};
  border-color: ${({ borderColor }) => borderColor};
  border-size: ${({ borderSize }) => borderSize};
  border-style: ${({ borderStyle }) => borderStyle};
  border-width: ${({ borderWidth }) => borderWidth};
  border-radius: ${({ borderRadius }) => borderRadius};
  background-color: ${({ backgroundColor }) => backgroundColor};
  box-shadow: ${({ boxShadow }) => boxShadow};
  min-height: ${({ minHeight }) => minHeight};
  min-width: ${({ minWidth }) => minWidth};
  height: ${({ height }) => height};
  width: ${({ width }) => width};
  text-align: ${({ textAlign }) => textAlign};
  margin: ${({ margin }) => margin};

  &:hover,
  &:focus {
    ${({ hoverStyles }) => hoverStyles};
  }

  &:active {
    ${({ activeStyles }) => activeStyles};
  }

  &:disabled {
    ${({ disabledStyles }) => disabledStyles};
  }

  ${({ extraStyles }) => extraStyles};
`;

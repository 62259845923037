import React, { useContext } from "react";
import { ThemeContext } from "styled-components";
import { Box, Center, Stack } from "../../atoms/layouts";
import { themeComponent } from "../../../util/themeUtils";
import { fallbackValues } from "./HeroImage.theme";
import withWindowSize from "../../withWindowSize";
import {
  FONT_WEIGHT_BOLD,
  FONT_WEIGHT_SEMIBOLD
} from "../../../constants/style_constants";
import * as Styled from "./HeroImage.styled";

const HeroImage = ({
  variant = "v1",
  themeValues,
  heading,
  subheading,
  description,
  imageUrl,
  minWidth = "100%",
  minHeight = "auto",
  contentMaxWidth = "76.5rem",
  contentSpacing = "0.5rem",
  headingVariant = "h1",
  secondaryHeadingVariant = "h2",
  extraStyles
}) => {
  const themeContext = useContext(ThemeContext);
  const { isMobile } = themeContext;

  return (
    <Styled.HeroContainer
      variant={variant}
      minWidth={minWidth}
      minHeight={minHeight}
      padding={isMobile ? "2rem" : "3rem 2rem"}
      extraStyles={extraStyles}
      imageUrl={imageUrl}
      isMobile={isMobile}
      theme={themeValues}
    >
      <Center
        maxWidth={contentMaxWidth}
        width={"100%"}
        intrinsic
        extraStyles={`
          flex-flow: unset;
          justify-content: ${Styled.getDeviceStyles(
            isMobile,
            themeValues.justifyContent
          )};
          flex-wrap: nowrap;
        `}
      >
        <Stack
          childGap={contentSpacing}
          extraStyles={`max-width: ${
            isMobile || variant === "v2" ? "100%" : "50%"
          };`}
        >
          {variant === "v1" ? (
            <Stack childGap="0">
              {heading && (
                <Styled.HeroHeading
                  variant="hero"
                  as={headingVariant}
                  weight={FONT_WEIGHT_BOLD}
                  color={themeValues.heroTextColor}
                  textAlign={Styled.getDeviceStyles(
                    isMobile,
                    themeValues.textAlign
                  )}
                  isMobile={isMobile}
                  theme={themeValues}
                >
                  {heading}
                </Styled.HeroHeading>
              )}
              {subheading && (
                <Styled.HeroSubheading
                  variant="large"
                  as={secondaryHeadingVariant}
                  weight={FONT_WEIGHT_SEMIBOLD}
                  textAlign={Styled.getDeviceStyles(
                    isMobile,
                    themeValues.textAlign
                  )}
                  color={themeValues.heroTextColor}
                  isMobile={isMobile}
                  theme={themeValues}
                >
                  {subheading}
                </Styled.HeroSubheading>
              )}
            </Stack>
          ) : (
            <>
              {heading && (
                <Styled.HeroHeading
                  variant="hero"
                  as={headingVariant}
                  weight={FONT_WEIGHT_BOLD}
                  color={themeValues.heroTextColor}
                  textAlign={Styled.getDeviceStyles(
                    isMobile,
                    themeValues.textAlign
                  )}
                  isMobile={isMobile}
                  theme={themeValues}
                >
                  {heading}
                </Styled.HeroHeading>
              )}
            </>
          )}
          {description && (
            <Box padding="0">
              <Styled.HeroDescription
                color={themeValues.heroTextColor}
                isMobile={isMobile}
                theme={themeValues}
              >
                {description}
              </Styled.HeroDescription>
            </Box>
          )}
        </Stack>
      </Center>
    </Styled.HeroContainer>
  );
};

export default withWindowSize(
  themeComponent(HeroImage, "HeroImage", fallbackValues, "v1")
);

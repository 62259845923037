import React from "react";
import styled from "styled-components";
import { Paragraph, Box } from "../../atoms";
import Title from "../../atoms/title/Title.js";
import { rgba, adjustHue, darken } from "polished";

const getHeroGradientStyles = ({ variant, isMobile, heroPrimaryColor }) => {
  const v1Gradient = `linear-gradient(
    90deg,
    ${heroPrimaryColor} 33%,
    transparent 100%
  )`;
  const v1GradientMobile = `linear-gradient(
    ${rgba(heroPrimaryColor, 0.8)},
    ${rgba(heroPrimaryColor, 0.8)}
  )`;
  const heroSecondaryColor = darken(0.18, adjustHue(-29, heroPrimaryColor));
  const v2Gradient = `linear-gradient(
    90deg,
    ${rgba(heroPrimaryColor, 0.8)} 0%,
    ${rgba(heroSecondaryColor, 0.8)} 100%
  )`;

  return variant === "v1"
    ? isMobile
      ? v1GradientMobile
      : v1Gradient
    : v2Gradient;
};

export const getDeviceStyles = (isMobile, theme) =>
  isMobile ? theme.mobile : theme.desktop;

export const HeroContainer = styled(
  ({ variant, imageUrl, isMobile, theme, extraStyles, ...props }) => (
    <Box {...props} />
  )
)`
  display: flex;
  background: ${({ variant, imageUrl, isMobile, theme }) =>
    `${getHeroGradientStyles({
      variant,
      isMobile,
      heroPrimaryColor: theme.heroPrimaryColor
    })},
    url(${imageUrl}) center / cover no-repeat, ${theme.heroPrimaryColor}`};
  ${({ extraStyles }) => extraStyles}
`;

export const HeroHeading = styled(({ theme, isMobile, ...props }) => (
  <Title {...props} />
))`
  font-size: ${({ theme, isMobile }) =>
    getDeviceStyles(isMobile, theme.headingFontSize)};
  color: ${({ color }) => color};
  font-weight: ${({ weight }) => weight};
  text-align: ${({ textAlign }) => textAlign};
  line-height: ${({ theme, isMobile }) =>
    getDeviceStyles(isMobile, theme.headingLineHeight)};
`;

export const HeroSubheading = styled(({ theme, isMobile, ...props }) => (
  <Title {...props} />
))`
  font-size: ${({ theme, isMobile }) =>
    getDeviceStyles(isMobile, theme.subheadingFontSize)};
  color: ${({ color }) => color};
  font-weight: ${({ weight }) => weight};
  text-align: ${({ textAlign }) => textAlign};
  line-height: ${({ theme, isMobile }) =>
    getDeviceStyles(isMobile, theme.subheadingLineHeight)};
`;

export const HeroDescription = styled(({ theme, isMobile, ...props }) => (
  <Paragraph {...props} />
))`
  line-height: ${({ theme, isMobile }) =>
    getDeviceStyles(isMobile, theme.descriptionLineHeight)};
  font-size: ${({ theme, isMobile }) =>
    getDeviceStyles(isMobile, theme.descriptionFontSize)};
  text-align: ${({ theme, isMobile }) =>
    getDeviceStyles(isMobile, theme.textAlign)};
`;

import React from "react";
import { equals } from "ramda";
import { FormInput } from "../../atoms/form-layouts";
import { createIdFromString, displayCurrency } from "../../../util/general";
import { Stack, Detail, Text, Box } from "../../atoms";
import { themeComponent } from "../../../util/themeUtils";
import { fallbackValues } from "./PartialAmountField.theme";
const PartialAmountField = ({
  lineItem,
  field,
  showErrors,
  errorMessages,
  moneyFormat,
  fieldActions,
  themeValues
}) => {
  const id = createIdFromString(
    `${lineItem.description}-${lineItem.subDescription}`
  );
  return (
    <FormInput
      ariaLabelledBy={null}
      id={id}
      labelTextWhenNoError={id}
      labelDisplayOverride={
        <Stack childGap="0px">
          <Box as="label" for={id} padding="0">
            <Detail
              as="span"
              extraStyles="display: block;"
              variant={themeValues.detailVariant}
              weight={themeValues.weightTitle}
            >
              {lineItem.description}
            </Detail>
            <Detail
              as="span"
              extraStyles="display: block;"
              variant={themeValues.detailVariant}
              weight="400"
            >
              {lineItem.subDescription}
            </Detail>
          </Box>
        </Stack>
      }
      key={lineItem.id}
      field={field}
      fieldActions={fieldActions}
      showErrors={showErrors}
      errorMessages={errorMessages}
      style={{ textAlign: "right" }}
      placeholder="$0.00"
      formatter={moneyFormat}
      isNum
      decorator={
        <Text variant="p">
          Amount owed:{"  "}
          {
            <Text variant="p" weight="600">
              {displayCurrency(lineItem.amount)}
            </Text>
          }
        </Text>
      }
    />
  );
};

function arePropsEqual(prevProps, nextProps) {
  return (
    equals(prevProps.errorMessages, nextProps.errorMessages) &&
    equals(prevProps.field, nextProps.field) &&
    equals(prevProps.showErrors, nextProps.showErrors) &&
    equals(prevProps.moneyFormat, nextProps.moneyFormat) &&
    equals(prevProps.lineItem, nextProps.lineItem)
  );
}

export default React.memo(
  themeComponent(
    PartialAmountField,
    "PartialAmountField",
    fallbackValues,
    "default"
  ),
  arePropsEqual
);

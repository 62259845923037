import React, { useContext, useState } from "react";
import { ThemeContext } from "styled-components";
import { useNavigate } from "react-router-dom";
import { groupBy } from "ramda";
import {
  AccountsIconSmall,
  ArrowRightIcon,
  AutopayIcon,
  Badge,
  Box,
  Detail,
  FindIconSmall,
  Grid,
  LinkCard,
  PlusCircleIcon,
  RegistrationBanner,
  SidebarStackContent,
  Stack,
  TabSidebar,
  Text,
  Title,
  util,
  withWindowSize,
  ContactCard,
  Placeholder,
  HeroImage
} from "@thecb/components";
import { BRIGHT_GREY } from "../../../../../../constants/colors";
import { FONT_WEIGHT_SEMIBOLD } from "../../../../../../constants/style_constants";
import {
  formatName,
  noop,
  isExternalLink
} from "../../../../../../util/general";
import { FILTERED_FINDABLE_ACCOUNTS_RESOURCE } from "../../Profile.state";
import { useLocation } from "react-router-dom";
import { FindAccountWrapper } from "./FindAccount.styled";
import {
  CORNFLOWER_BLUE,
  ROYAL_BLUE_VIVID,
  LINK_WATER,
  WHITE,
  MANATEE_GREY,
  SEA_GREEN,
  TRANSPARENT,
  GHOST_GREY
} from "../../../../../../constants/colors";
import { isInCustomerManagement } from "../../../../../../util/router-utils";
import CustomContentCards from "../../../../../../components/custom-content-cards";
import { useDispatch, useSelector } from "react-redux";
import { cacheLoginRedirectUrl } from "../../../login/Login.state";
import parse from "html-react-parser";
import {
  getHeroImage,
  getContactCard,
  getShowContactCard,
  getClientDisplayName,
  getHasAutopay,
  getLinkCards
} from "../../Profile.selectors";

const reconstructedLinkCardFallbackValues = {
  color: ROYAL_BLUE_VIVID,
  borderColor: LINK_WATER,
  backgroundColor: WHITE,
  activeBackgroundColor: CORNFLOWER_BLUE,
  disabledColor: MANATEE_GREY,
  disabledBackgroundColor: TRANSPARENT,
  disabledBorderColor: GHOST_GREY
};

// This component is rendered at root (`/`), and `/profile/find`
const ProfileFindAccount = ({
  resources,
  obligationsConfig,
  payDotEnabled,
  isLoggedIn,
  clientMetadata
}) => {
  const TABLET_BREAKPOINT = 1048;
  const themeContext = useContext(ThemeContext);
  const { isMobile } = themeContext;
  const linkCardThemeValues = util.theme.createThemeValues(
    themeContext,
    reconstructedLinkCardFallbackValues,
    "LinkCard",
    "primary"
  );
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [customContentCards, setCustomContentCards] = useState(
    obligationsConfig
      .filter(obligation => obligation.hasCustomContentCard)
      .map(obligation => ({
        ...obligation?.customContentCard,
        isOpen: true,
        displayedAtPages:
          obligation?.customContentCard?.displayedAtPages ?? "all"
      }))
  );
  const location = useLocation();
  const isPayDotPage =
    (payDotEnabled && !location.pathname.includes("/profile/find")) ?? false;

  const findableAccounts = useSelector(
    state => state.global?.workflows?.data?.findableAccounts ?? []
  );
  const accounts = isPayDotPage
    ? findableAccounts
    : // For some profile clients, a user can only add a single obligation from a given account.
      // These are the clients where canAddObligation = false. Call these 'single obligation clients'.
      resources?.[FILTERED_FINDABLE_ACCOUNTS_RESOURCE] ?? [];

  /******************* Paydot Components ***************************/
  const contactCard = useSelector(state => getContactCard(state));
  const showContactCard = useSelector(state => getShowContactCard(state));
  const heroImage = useSelector(state => getHeroImage(state));
  const heroImageURL = clientMetadata?.data?.heroImage;

  const linkCards = useSelector(state => getLinkCards(state));
  const accountsAndLinkCards = linkCards
    ? [...accounts, ...linkCards]
    : accounts;
  const sortedAccountsAndLinkCards = [...accountsAndLinkCards].sort((a, b) =>
    (a?.title?.toUpperCase() ?? "").localeCompare(b?.title?.toUpperCase() ?? "")
  );
  /*
    This component is used to render the accounts grid in the new paydot design.
    This will eventually replace the old subClientAccounts function.
  */
  const renderAccountsGrid = accounts => {
    return (
      <Box padding="1.5rem 0 0.5rem" dataQa="find-accounts-grid">
        <Grid columnGap="1rem" minColWidth={accounts?.length < 3 ? 25 : 19}>
          {accounts?.map(account => (
            <React.Fragment key={account.slug}>
              {renderAccountCard(account)}
            </React.Fragment>
          ))}
        </Grid>
      </Box>
    );
  };
  /*
    This will eventually replace the old actionNameForWorkflowType function.
  */
  const actionNameForWorkflowTypeV2 = workflowType => {
    switch (workflowType) {
      case "default":
        return "Pay";
      case "obligation_association":
        return "Find Account";
      default:
        return "Find Account";
    }
  };
  /*
    This component is used to render the account cards in the new paydot design.
    This will eventually replace the old renderAccount function.
  */
  const renderAccountCard = ({
    title,
    subtitle,
    slug,
    workflow_type,
    link // only used for external accounts
  }) => {
    const workflowActionName =
      workflow_type && actionNameForWorkflowTypeV2(workflow_type);
    const isObligationAssociation =
      workflow_type && workflow_type === "obligation_association";
    const isExternalAccount = !!link;
    const handleNavigation = () => {
      if (isInCustomerManagement) {
        return noop;
      } else if (isExternalAccount && isExternalLink(link)) {
        window.open(link, "_blank");
      } else if (!isLoggedIn && isPayDotPage && isObligationAssociation) {
        // Temporarily cache `/service/${slug}` so we can redirect to it after login
        dispatch(cacheLoginRedirectUrl(`/service/${slug}`));
        navigate(`/login`);
      } else if (slug) {
        navigate(`/service/${slug}`);
      }
      return;
    };
    const linkCardSlug = isExternalAccount
      ? title?.replace(/\s+/g, "-")?.toLowerCase()
      : slug;
    return (
      <LinkCard
        key={linkCardSlug}
        isExternalLink={isExternalAccount}
        extraStyles={`
          height: 11.75rem;
          max-width: none;
          min-width: auto;
          ${isInCustomerManagement ? `` : `transition: all .2s ease-in-out;`}
          p { color: ${BRIGHT_GREY}; font-weight: normal; }`}
        extraHoverStyles={
          isInCustomerManagement
            ? `box-shadow: none; cursor: default; .show-on-hover {opacity: 0}`
            : `.show-on-hover {opacity: 1}`
        }
        title={title}
        subtitle={subtitle}
        subtitlePadding={"0 0 0.5rem"}
        slug={linkCardSlug}
        onClick={handleNavigation}
        disabled={isInCustomerManagement}
        showLeft={true}
        leftContent={
          isObligationAssociation && (
            <Badge
              label="Autopay"
              variant={isInCustomerManagement ? "disabled" : "success"}
              Icon={() =>
                AutopayIcon({
                  fill: isInCustomerManagement ? MANATEE_GREY : SEA_GREEN
                })
              }
            />
          )
        }
        showRight={true}
        rightContent={
          <Box
            data-qa={`find-or-pay-${linkCardSlug}`}
            extraStyles={`
              display: flex;
              gap: 6px;
              justify-content: space-between;
              align-items: center;
            `}
            padding="0"
            hoverStyles=".show-on-hover {opacity: 1;}"
          >
            {!isExternalAccount && (
              <Text
                variant="pS"
                color={
                  isInCustomerManagement
                    ? linkCardThemeValues.disabledColor
                    : linkCardThemeValues.color
                }
                data-qa={linkCardSlug}
                id={`workflow-${linkCardSlug}`}
              >
                {workflowActionName}
              </Text>
            )}
            {isObligationAssociation ? (
              <PlusCircleIcon
                labelledBy={`workflow-${linkCardSlug}`}
                color={
                  isInCustomerManagement
                    ? linkCardThemeValues.disabledColor
                    : linkCardThemeValues.color
                }
                data-qa={`find-${linkCardSlug}`}
              />
            ) : (
              <ArrowRightIcon
                labelledBy={`workflow-${linkCardSlug}`}
                color={
                  isInCustomerManagement
                    ? linkCardThemeValues.disabledColor
                    : linkCardThemeValues.color
                }
                data-qa={`pay-${linkCardSlug}`}
              />
            )}
          </Box>
        }
      />
    );
  };
  /******************* Paydot Components ***************************/

  const groupAccountsBySubClient = groupBy(account => account.sub_client);

  const groupedAccounts = Object.entries(
    groupAccountsBySubClient(accounts)
  ).sort(([agencyNameA, _valueA], [agencyNameB, _valueB]) =>
    agencyNameA.localeCompare(agencyNameB)
  );

  const subClientAccounts = ([subClientName, accounts]) => {
    const accountValues = Object.values(accounts);
    return (
      <Box
        padding="1.5rem 0 0.5rem"
        dataQa={`findable-account-${subClientName}`}
        key={`findable-account-${subClientName}`}
      >
        <Stack childGap="0.5rem">
          {subClientName &&
            subClientName != "undefined" &&
            groupedAccounts.length > 1 && (
              <Detail as="h2" variant="regular">
                {formatName(subClientName)}
              </Detail>
            )}
          <Box padding="0">
            <Grid
              columnGap={"1.5rem"}
              minColWidth={
                /* % widths based on number of columns per row,
                minus the column gap */
                isMobile
                  ? `calc(100% - 1.5rem)`
                  : !isMobile && window.innerWidth <= TABLET_BREAKPOINT
                  ? `calc(50% - 1.5rem)`
                  : `calc(33.33% - 1.5rem)`
              }
            >
              {accountValues.map(renderAccount)}
            </Grid>
          </Box>
        </Stack>
      </Box>
    );
  };

  const actionNameForWorkflowType = workflowType => {
    switch (workflowType) {
      case "default":
        return "Pay";
      case "obligation_association":
        return "Find";
      default:
        return "Find";
    }
  };

  const renderAccount = ({ title, subtitle, slug, workflow_type }) => {
    const workflowActionName = actionNameForWorkflowType(workflow_type);
    const isObligationAssociation = workflow_type === "obligation_association";
    return (
      <LinkCard
        key={slug}
        extraStyles={
          isInCustomerManagement ? `` : `transition: all .2s ease-in-out;`
        }
        extraHoverStyles={
          isInCustomerManagement
            ? `box-shadow: none; cursor: default; .show-on-hover {opacity: 0}`
            : `.show-on-hover {opacity: 1}`
        }
        title={title}
        subtitle={subtitle}
        slug={slug}
        onClick={
          isInCustomerManagement ? noop : () => navigate(`/service/${slug}`)
        }
        disabled={isInCustomerManagement}
        showLeft={true}
        leftContent={
          isObligationAssociation && (
            <Badge
              label="Autopay Available"
              variant={isInCustomerManagement ? "disabled" : "success"}
              Icon={() =>
                AutopayIcon({
                  fill: isInCustomerManagement ? MANATEE_GREY : SEA_GREEN
                })
              }
            />
          )
        }
        showRight={true}
        rightContent={
          <Box
            data-qa={`find-or-pay-${slug}`}
            extraStyles={`
              display: flex;
              gap: 6px;
              justify-content: space-between;
              align-items: center;
            `}
            padding="0"
            hoverStyles=".show-on-hover {opacity: 1;}"
          >
            <Text
              variant="pS"
              className="show-on-hover"
              color={
                isInCustomerManagement
                  ? linkCardThemeValues.disabledColor
                  : linkCardThemeValues.color
              }
              data-qa={slug}
              id={`workflow-${slug}`}
              extraStyles={`
                transition: opacity .2s ease-in-out;
                opacity: ${isMobile ? "1" : "0"}
              `}
            >
              {workflowActionName}
            </Text>
            {isObligationAssociation ? (
              <PlusCircleIcon
                labelledBy={`workflow-${slug}`}
                color={
                  isInCustomerManagement
                    ? linkCardThemeValues.disabledColor
                    : linkCardThemeValues.color
                }
                data-qa={`find-${slug}`}
              />
            ) : (
              <ArrowRightIcon
                labelledBy={`workflow-${slug}`}
                color={
                  isInCustomerManagement
                    ? linkCardThemeValues.disabledColor
                    : linkCardThemeValues.color
                }
                data-qa={`pay-${slug}`}
              />
            )}
          </Box>
        }
      />
    );
  };

  const isRouteActive = route => location.pathname === route;
  const hasAutopay = useSelector(getHasAutopay);
  const payDotSidebarConfig = [
    {
      route: "/",
      text: "Find",
      icon: (
        <FindIconSmall
          iconIndex={0}
          variant={isRouteActive("/") ? "primary" : "secondary"}
        />
      ),
      content: (
        <>
          <CustomContentCards
            cardExtraStyles={`
              // Match width and breakpoints of cards below
              min-width: 288px;
              max-width: ${isMobile ? "100%" : "calc(66.66% - 0.5rem)"};
            `}
            cards={customContentCards}
            setCards={setCustomContentCards}
          />
          {renderAccountsGrid(sortedAccountsAndLinkCards)}
        </>
      )
    },
    hasAutopay &&
      !isLoggedIn && {
        route: "/accounts",
        text: "Accounts",
        icon: (
          <AccountsIconSmall
            iconIndex={0}
            variant={isRouteActive("/accounts") ? "primary" : "secondary"}
          />
        ),
        content: (
          <Placeholder
            isLink
            variant="large"
            destination="/registration"
            text="Register to Add an Account"
            largeIcon="accounts"
            dataQa="Register to Add an Account"
          />
        )
      }
  ].filter(Boolean);

  const currentRoute = payDotSidebarConfig.find(
    configItem => configItem.route === location.pathname
  );

  const payDotContent = (
    <SidebarStackContent
      sidebarContent={
        <Stack childGap="2rem">
          <TabSidebar
            links={payDotSidebarConfig.map(configItem => ({
              route: configItem.route,
              text: configItem.text,
              active: isRouteActive(configItem.route),
              icon: configItem.icon
            }))}
            isMobile={isMobile}
            key="tab-sidebar"
            minHeight="auto"
          />
          {!isMobile && showContactCard && (
            <ContactCard
              title={contactCard?.title}
              content={parse(contactCard?.content)}
              links={contactCard?.links}
              secondTitle={contactCard?.secondTitle}
            />
          )}
        </Stack>
      }
      sidebarWrapperPadding={isMobile ? "0 0 32px 0" : "32px 16px 48px 12px"}
      sidebarWrapperMaxWidth="78.5rem"
      sidebarContentGap={isMobile ? "24px" : "4.75rem"}
      sidebarTargetWidth={isMobile ? "100%" : "14rem"}
      mainContentMinWidth={isMobile && "100%"}
      mainContent={
        <Stack childGap="1.5rem">
          <Box padding={isMobile ? "0 16px" : "0"}>{currentRoute?.content}</Box>
          {isMobile && showContactCard && (
            <ContactCard
              title={contactCard?.title}
              content={parse(contactCard?.content)}
              links={contactCard?.links}
              secondTitle={contactCard?.secondTitle}
              extraStyles={`margin: 1.5rem 1rem 0;`}
            />
          )}
        </Stack>
      }
    />
  );

  const clientDisplayName = useSelector(getClientDisplayName);

  return (
    <FindAccountWrapper>
      {isPayDotPage && !isLoggedIn ? (
        <>
          <HeroImage
            variant={heroImage?.variant}
            heading={heroImage?.title}
            subheading={heroImage?.subtitle}
            description={heroImage?.description}
            imageUrl={heroImageURL}
          />
          {clientDisplayName && (
            <RegistrationBanner clientName={clientDisplayName} titleAs="h3" />
          )}
          {payDotContent}
        </>
      ) : (
        <>
          <Title
            variant="small"
            as={payDotEnabled ? "h2" : "h1"}
            weight={FONT_WEIGHT_SEMIBOLD}
            data-qa="findScreenTitle"
            margin="0 0 1rem"
          >
            Find and Pay
          </Title>
          <CustomContentCards
            cardExtraStyles={`
              // Match width and breakpoints of cards below
              min-width: 288px;
              max-width: ${isMobile ? "100%" : "calc(66.66% - 0.5rem)"};
            `}
            cards={customContentCards}
            setCards={setCustomContentCards}
          />
          {payDotEnabled
            ? renderAccountsGrid(sortedAccountsAndLinkCards)
            : groupedAccounts.map(subClientAccounts)}
        </>
      )}
    </FindAccountWrapper>
  );
};

export default withWindowSize(ProfileFindAccount);

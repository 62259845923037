import React, { useContext } from "react";
import * as R from "ramda";
import { Box, Stack } from "../../atoms/layouts";
import { ExternalLink, InternalLink } from "../../atoms";
import { ThemeContext } from "styled-components";
import * as Styled from "./ContactCard.styled";
import { createIdFromString } from "../../../util/general";
import {
  FONT_SIZE,
  FONT_WEIGHT_SEMIBOLD
} from "../../../constants/style_constants";
import ExternalLinkIcon from "../../atoms/icons/ExternalLinkIcon";
import SolidDivider from "../../atoms/solid-divider";
import { ROYAL_BLUE_VIVID, WHITE } from "../../../constants/colors";
import { createThemeValues } from "../../../util/themeUtils";
import { TextWrapStyles } from "./ContactCard.styled";
import { URL_TEST } from "../../../constants/regex_constants";

const ContactCard = ({
  title,
  secondTitle = "Helpful Links",
  content = "",
  links = [],
  extraStyles = "",
  titleVariant = "h3",
  ariaLabel = "Contact Information",
  linkVariant = "primary"
}) => {
  const themeContext = useContext(ThemeContext);
  const { isMobile } = themeContext;
  const linkFallbackValues = { externalLinkColor: ROYAL_BLUE_VIVID };
  const linkThemeValues = createThemeValues(
    themeContext,
    linkFallbackValues,
    "Link",
    linkVariant
  );

  return (
    <Styled.Container
      borderRadius="8px"
      background={WHITE}
      boxShadow="0px 2px 4px rgba(0, 0, 0, 0.25)"
      dataQa={createIdFromString(title, "contact-card")}
      maxWidth="100%"
      minWidth={isMobile ? "240px" : "208px"}
      minHeight="141px"
      padding="1.5rem"
      extraStyles={extraStyles}
      role="complementary"
      aria-label={ariaLabel}
    >
      <Stack
        childGap="8px"
        bottomItem={3}
        justify="space-between"
        style={{ width: "100%" }}
        fullHeight
      >
        <Box padding={0} width="100%">
          <Styled.Title
            variant={titleVariant}
            margin={0}
            fontSize={FONT_SIZE.MD}
            weight={FONT_WEIGHT_SEMIBOLD}
          >
            {title}
          </Styled.Title>
        </Box>
        <Box padding={"0"} width="100%">
          <Styled.Content>{content}</Styled.Content>
        </Box>
      </Stack>
      {links.length > 0 && (
        <>
          <SolidDivider aria-hidden={true} />
          <Stack
            childGap={"8px"}
            bottomItem={3}
            justify="space-between"
            style={{ width: "100%" }}
            fullHeight
          >
            <Styled.Title
              variant={titleVariant}
              margin={0}
              fontSize={FONT_SIZE.MD}
              weight={FONT_WEIGHT_SEMIBOLD}
            >
              {secondTitle}
            </Styled.Title>
            <Stack
              childGap={"4px"}
              justify="space-between"
              extraStyles={`width: 100%;`}
              fullHeight
            >
              {links.map(link => {
                const linkID = createIdFromString(
                  link.text,
                  "contact-card-link"
                );
                return (
                  <React.Fragment key={linkID}>
                    {R.test(URL_TEST, link.link) ? (
                      <ExternalLink
                        dataQa={linkID}
                        href={link.link}
                        newTab={true}
                        isUnderlined={false}
                        extraStyles={`
                          flex-direction: row;
                          align-items: center;
                          align-content: flex-start;
                          justify-content: space-between;
                          word-break: break-word;
                          ${TextWrapStyles}
                        `}
                        size={FONT_SIZE.SM}
                        lineHeight="1.313rem"
                      >
                        {link.text}
                        <ExternalLinkIcon
                          linkColor={linkThemeValues.externalLinkColor}
                          text={link.text}
                          ariaLabel="(Opens in a new tab)"
                        />
                      </ExternalLink>
                    ) : (
                      <InternalLink
                        to={link.link}
                        dataQa={linkID}
                        fontSize={FONT_SIZE.SM}
                        color={linkThemeValues.externalLinkColor}
                        isUnderlined={false}
                        active={false}
                        lineheight="1.313rem"
                        extraStyles={`
                          word-break: break-word;
                          ${TextWrapStyles}
                        `}
                      >
                        {link.text}
                      </InternalLink>
                    )}
                  </React.Fragment>
                );
              })}
            </Stack>
          </Stack>
        </>
      )}
    </Styled.Container>
  );
};

export default ContactCard;

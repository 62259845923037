import styled, { css } from "styled-components";
import Heading from "../../atoms/heading";
import { Box } from "../../atoms/layouts";
import { FONT_SIZE } from "../../../constants/style_constants";

export const TextWrapStyles = css`
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  max-width: 100%;
`;

export const Container = styled(Box)`
  height: fit-content;
  display: flex;
  padding: 1rem;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.5rem;
  border-radius: 8px;
  ${({ extraStyles }) => extraStyles}
`;

export const Title = styled(Heading)`
  ${TextWrapStyles}
`;

export const Content = styled(Box)`
  word-break: break-word;
  padding: 0;
  font-size: ${FONT_SIZE.SM};
  line-height: 1.313rem;
  letter-spacing: 0.14px;
  ${TextWrapStyles}

  p {
    margin: 0;
  }

  p,
  span {
    line-height: 1.313rem;
  }
`;

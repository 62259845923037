import React from "react";
import styled from "styled-components";
import { Motion } from "../../components/atoms/layouts";

export const SpinnerContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;

export const SpinnerIconWrapper = styled(Motion)`
  width: ${({ isMobile }) => (isMobile ? "18" : "21")}px;
  height: ${({ isMobile }) => (isMobile ? "18" : "21")}px;
`;

const SpinnerIcon = ({ color, isMobile }) => (
  <SpinnerContainer>
    <SpinnerIconWrapper
      initial={{ rotate: 0 }}
      animate={{ rotate: 360 }}
      transition={{ duration: 1, ease: "linear", repeat: Infinity }}
      isMobile={isMobile}
    >
      <svg
        viewBox={`0 0 24 24`}
        height="100%"
        width="100%"
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
      >
        <defs>
          <path
            d="M72.1558149,36 C72.0733136,36 72.0151877,35.9981252 71.9829372,35.9970003 C66.1928468,35.9876262 60,31.1685779 60,23.9985001 C60,16.821298 66.204847,12 71.9998125,12 C78.2804106,12 84,17.7189638 84,23.9985001 C84,24.4124613 83.6639947,24.7484298 83.2499883,24.7484298 C82.8356068,24.7484298 82.4999766,24.4124613 82.4999766,23.9985001 C82.4999766,18.5041402 77.4951484,13.4998594 71.9998125,13.4998594 C66.9289833,13.4998594 61.5000234,17.7185889 61.5000234,23.9985001 C61.5000234,30.2784114 66.9289833,34.4971409 71.9998125,34.4971409 C72.0129377,34.4971409 72.0260629,34.4975159 72.0391881,34.4982658 C72.0789387,34.4990157 76.2268785,34.6471268 79.907186,30.9675968 C80.2000656,30.6747492 80.675198,30.6747492 80.9677026,30.9675968 C81.2605822,31.2604443 81.2605822,31.7351498 80.9677026,32.0279974 C77.2236441,35.7716464 73.1034547,36 72.1558149,36 L72.1558149,36 Z"
            id="spinner-path-1"
          />
        </defs>
        <g id="Symbols" fill="none" fillRule="evenodd">
          <g
            id="Button/Primary/Standard/Loading"
            transform="translate(-60 -12)"
          >
            <mask id="mask-2" fill={color || "white"}>
              <use xlinkHref="#spinner-path-1" />
            </mask>
            <use
              id="loader"
              fill={color || "white"}
              xlinkHref="#spinner-path-1"
            />
          </g>
        </g>
      </svg>
    </SpinnerIconWrapper>
  </SpinnerContainer>
);

export default SpinnerIcon;

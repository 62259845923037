import {
  CHARADE_GREY,
  CORNFLOWER_BLUE,
  ROYAL_BLUE_VIVID
} from "../../../constants/colors";

export const fallbackValues = {
  primaryColor: ROYAL_BLUE_VIVID,
  secondaryColor: CHARADE_GREY,
  background: CORNFLOWER_BLUE
};

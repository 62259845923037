import * as Rx from "rxjs";
import * as RxOp from "rxjs/operators";
import S from "sanctuary";
import * as $ from "sanctuary-def";
import * as R from "ramda";
import { fetchResources } from "../user-profile/Profile.state";
import {
  SUBMIT_LOGIN_FORM,
  loginAlertBarAction,
  loginSuccess,
  loginError,
  clearLoginRedirectUrl
} from "./Login.state";
import { replaceAlerts } from "../../../../components/alert-bar/AlertBar.state";
import { loginUser } from "./graphql/Queries";
import { push } from "connected-react-router";
import {
  LOGOUT,
  REFRESH_TOKEN_EXPIRED,
  clearLocalStorage
} from "../../../../state/reducer";
import {
  getGraphqlServiceEndpoint,
  getClientSlug,
  getWalletScreenV2EnabledFlag
} from "../../../../util/state";
import {
  closeWalletSlider,
  changePanel,
  setVerificationEmail,
  resetExpiredSession,
  setLoadingProfileResources
} from "../../../checkout/pages/payment/Payment.state";
import { populateEmail } from "../account-verification/AccountVerification.state";

const genericErrorMessage =
  "There was a problem logging in to your account. Please try again.";
const wrongInfoErrorMessage =
  "The information you entered doesn't match our records.";

const loginUserParams = state => {
  return {
    endpoint: getGraphqlServiceEndpoint(state),
    clientSlug: getClientSlug(state),
    email: state.login.forms.loginForm.email.rawValue,
    password: state.login.forms.loginForm.password.rawValue
  };
};

export const onRefreshTokenExpireEpic = action$ =>
  action$.ofType(REFRESH_TOKEN_EXPIRED).pipe(
    RxOp.flatMap(() =>
      Rx.of(
        loginAlertBarAction(
          replaceAlerts({
            heading: "Session Expired",
            text: "Your session has expired. Please login again.",
            type: "error"
          }),
          push("/login")
        )
      )
    )
  );

const onErrorEpic = ({ errorMessage }) =>
  Rx.of(
    loginError(errorMessage),
    loginAlertBarAction(
      replaceAlerts({
        heading: "Login Failed",
        text: errorMessage,
        variant: "error"
      })
    )
  );

export const logoutEpic = (action$, state$) =>
  action$.ofType(LOGOUT).pipe(
    RxOp.flatMap(() => {
      const payDotEnabled =
        state$?.value?.global?.settings?.data?.payDotEnabled ?? false;
      return state$.value.checkout.isExpiredSession
        ? Rx.of(clearLocalStorage())
        : Rx.of(clearLocalStorage(), push(payDotEnabled ? "/" : "/login"));
    })
  );

const onPendingEpic = ({ errorMessage }, inWallet, email) =>
  inWallet
    ? Rx.of(
        setVerificationEmail(email),
        loginError(errorMessage),
        changePanel("login", "next"),
        changePanel("verifyAccount", "onScreen")
      )
    : Rx.of(
        loginError(errorMessage),
        populateEmail(email),
        push("/verify-account?referredFrom=login")
      );

const parseError = S.pipe([
  S.get(S.is($.Array($.Object)))("errors"),
  S.chain(S.head),
  S.chain(S.get(S.is($.String))("message")),
  S.map(
    S.ifElse(S.equals("unauthenticated"))(S.K(wrongInfoErrorMessage))(
      S.K(genericErrorMessage)
    )
  ),
  S.fromMaybe(genericErrorMessage)
]);

const parseResponse = response =>
  S.pipe([
    S.gets(S.is($.String))(["performLoginForUser", "token", "key"]),
    S.maybeToNullable
  ])(response);

const renderCheckoutPage = state => {
  const isInvoice = R.path(["checkout", "payment", "isInvoice"], state);
  const invoiceId = R.path(["checkout", "payment", "invoiceId"], state);
  const serviceName = R.path(["checkout", "payment", "serviceName"], state);
  const payment = R.path(["checkout", "payment"], state);

  const walletScreenV2Enabled = getWalletScreenV2EnabledFlag(state);
  const invoiceOrServiceRouting = isInvoice
    ? push(`/payment/invoice/${invoiceId}/payment`)
    : push(`/payment/service/${serviceName}/payment`);

  if (walletScreenV2Enabled) {
    return !isInvoice && !payment?.isProfilePayment
      ? push(`/payment/service/${serviceName}/wallet`)
      : invoiceOrServiceRouting;
  } else {
    return invoiceOrServiceRouting;
  }
};

export const submitLoginFormEpic = (action$, state$) =>
  action$.ofType(SUBMIT_LOGIN_FORM).pipe(
    RxOp.flatMap(action =>
      Rx.from(loginUser(loginUserParams(state$.value))).pipe(
        RxOp.flatMap(refreshToken =>
          action.payload.inWallet
            ? Rx.of(
                resetExpiredSession(),
                loginSuccess(parseResponse(refreshToken)),
                setLoadingProfileResources(true),
                closeWalletSlider(),
                fetchResources(),
                renderCheckoutPage(state$.value)
              )
            : Rx.of(
                resetExpiredSession(),
                loginSuccess(parseResponse(refreshToken)),
                push(action.payload.loginRedirectUrl, {
                  options: { prevPage: "login" }
                }),
                clearLoginRedirectUrl()
              )
        ),
        RxOp.catchError(err =>
          R.cond([
            [
              err =>
                R.equals(
                  "Unable to authenticate. The profile is not active.",
                  R.path(["response", "errors", 0, "message"], err)
                ),
              err =>
                onPendingEpic(
                  {
                    errorMessage: err.response
                      ? parseError(err.response)
                      : err.message
                  },
                  action.payload.inWallet,
                  state$.value.login.forms.loginForm.email.rawValue
                )
            ],
            [
              R.T,
              err =>
                onErrorEpic({
                  errorMessage: err.response
                    ? parseError(err.response)
                    : err.message
                })
            ]
          ])(err)
        )
      )
    )
  );

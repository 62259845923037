import React from "react";
import {
  Box,
  ButtonWithLink,
  Card,
  constants,
  Paragraph
} from "@thecb/components";
import { useLocation } from "react-router";

const CustomContentCards = ({
  cards,
  setCards,
  maxWidth,
  cardExtraStyles = ""
}) => {
  const location = useLocation();
  const filterCards = cards =>
    cards.filter(
      card =>
        (card.displayedAtPages === "all" ||
          card.displayedAtPages.some(page =>
            location.pathname.includes(page)
          )) &&
        card.isOpen
    );
  return filterCards(cards).map(
    (card, index) =>
      card.titleText &&
      (card.bodyText || card.sequentialSteps?.length) && (
        <Card
          hasContentBackgroundColor
          extraStyles={`
              h2 { font-weight: ${constants.fontWeights.FONT_WEIGHT_SEMIBOLD}}
              ol { margin: 0; padding: 4px 0 0; list-style-position: inside; }
              p { max-width: ${maxWidth}; }
              ${cardExtraStyles}
            `}
          key={`${card.titleText}-${index}`}
          titleText={card.titleText}
          showQuitLink
          onQuitClick={() =>
            setCards(cards.filter((_card, cardIndex) => cardIndex !== index))
          }
          textAs="div"
          text={
            <>
              {card.bodyText && (
                <Paragraph margin="0.5rem 0 0">{card.bodyText}</Paragraph>
              )}
              {card.sequentialSteps?.length && (
                <ol>
                  {card.sequentialSteps.map(step => (
                    <li key={step}>{step}</li>
                  ))}
                </ol>
              )}
            </>
          }
        >
          {card.button?.text && card.button?.destination && (
            <Box padding="0 24px 24px">
              <ButtonWithLink
                extraStyles="border-radius: 4px; margin: 0; flex-basis: 100%; flex-grow: 1;"
                linkExtraStyles="button > span > span { white-space: normal; }"
                url={card.button.destination}
                text={card.button.text}
              />
            </Box>
          )}
        </Card>
      )
  );
};

export default CustomContentCards;
